import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme, ButtonGroup, Button, makeStyles } from '@material-ui/core';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import TwoWheelerIcon from '@material-ui/icons/TwoWheeler';
import { useTranslation } from 'react-i18next';
import translateText from './translate'; // Import your translation function here
import PriceTableITheorie from './PriceTableTheorie';
import PriceTableMoto from './PriceTableMoto';
import LicenseExchangeInfo from './LicenceExchange';
import { MdMultipleStop } from 'react-icons/md';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '20px',
  },
  buttonGroup: {
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide on mobile
    },
  },
  button: {
    textTransform: 'none',
    borderRadius: '20px',
    padding: '15px 30px',
    fontSize: '1.2rem',
  },
  selectedButton: {
    backgroundColor: '#2196f3',
    color: '#ffffff',
  },
  icon: {
    marginRight: '10px',
    fontSize: '2rem',
  },
  tabTitle: {
    color: '#666',
  },
  mobileTabContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      display: 'none', // Hide on larger screens
    },
  },
  mobileTabRow: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  mobileTab: {
    flex: '0 0 auto',
    minWidth: '100px',
    padding: '10px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '0 10px',
  },
  mobileTabSelected: {
    backgroundColor: '#2196f3',
    color: '#ffffff',
    borderRadius: '10px',
  },
}));

interface Buttons {
  id: string;
  icon: JSX.Element;
  title: string;
}

const CourseTabs = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation(); // Initialize useTranslation hook
  const [selectedTab, setSelectedTab] = useState('auto'); // State to track selected tab
  const [translatedTabs, setTranslatedTabs] = useState<Buttons[]>([]); // State to store translated tabs
  const { lng } = useParams<{ lng: string }>();
  useEffect(() => {
    if (lng && ['en', 'fr'].includes(lng)) {
      i18n.changeLanguage(lng);
    } else {
      // Handle unsupported language or redirect
      console.error('Unsupported language:', lng);
    }
  }, [lng, i18n]);
  const tabData = [
    {
      id: 'auto',
      icon: <DirectionsCarIcon className={classes.icon} />,
      title: 'PERMIS VOITURE', // Original text to translate
    },
    {
      id: 'moto',
      icon: <TwoWheelerIcon className={classes.icon} />,
      title: 'PERMIS MOTO', // Original text to translate
    },
    {
      id: 'echange',
      icon: <MdMultipleStop className={classes.icon} />,
      title: 'ECHANGE DE PERMIS', // Original text to translate
    }
  ];

  useEffect(() => {
    const fetchTranslations = async () => {
      const translatedLabels = await Promise.all(
        tabData.map(async (tab) => {
          const translatedLabel = await translateText(tab.title, i18n.language); // Translate each tab title
          return { ...tab, title: translatedLabel }; // Update the title with translated text
        })
      );

      setTranslatedTabs(translatedLabels); // Update state with translated tabs
    };

    fetchTranslations();
  }, [i18n.language]);

  const handleTabClick = (tabId) => {
    setSelectedTab(tabId);
  };

  // Check if screen size is small (mobile)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className="price price_index" style={{ paddingTop: '50px', paddingBottom: '100px' }}>
      <Container className={classes.container}>
        {/* Render mobile tabs if screen size is small */}
        {isMobile ? (
          <div className={classes.mobileTabContainer}>
            <div className={classes.mobileTabRow}>
              {translatedTabs.slice(0, 2).map((tab) => (
                <div
                  key={tab.id}
                  className={`${classes.mobileTab} ${selectedTab === tab.id ? classes.mobileTabSelected : ''}`}
                  onClick={() => handleTabClick(tab.id)}
                >
                  {tab.icon}
                  <Typography variant="h6" className={classes.tabTitle}>
                    {tab.title}
                  </Typography>
                </div>
              ))}
            </div>
            <div className={classes.mobileTabRow}>
              {translatedTabs.slice(2, 3).map((tab) => (
                <div
                  key={tab.id}
                  className={`${classes.mobileTab} ${selectedTab === tab.id ? classes.mobileTabSelected : ''}`}
                  onClick={() => handleTabClick(tab.id)}
                >
                  {tab.icon}
                  <Typography variant="h6" className={classes.tabTitle}>
                    {tab.title}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        ) : (
          // Render ButtonGroup for larger screens
          <ButtonGroup
            variant="outlined"
            color="primary"
            className={classes.buttonGroup}
            aria-label="course tabs"
          >
            {translatedTabs.map((tab) => (
              <Button
                key={tab.id}
                className={`${classes.button} ${selectedTab === tab.id ? classes.selectedButton : ''}`}
                style={{
                  backgroundColor: selectedTab === tab.id ? 'lavenderblush' : 'transparent'
                }}
                onClick={() => setSelectedTab(tab.id)}
              >
                {tab.icon}
                <Typography variant="h6" className={classes.tabTitle}>
                  {tab.title}
                </Typography>
              </Button>
            ))}
          </ButtonGroup>
        )}

        {/* Additional content based on selected tab */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* Example: Display content based on selected tab */}
            {selectedTab === 'auto' && <PriceTableITheorie />}
            {selectedTab === 'moto' && <PriceTableMoto />}
            {selectedTab === 'echange' && <LicenseExchangeInfo />}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CourseTabs;
