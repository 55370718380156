import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Typography, Grid, Button } from '@material-ui/core'; // Import Grid for responsive layout
import { useTranslation } from 'react-i18next';
import translateText from './translate'; // Import your translateText function
import WhatsAppIcon from '@material-ui/icons/WhatsApp'; // Import the WhatsApp icon from Material-UI
import { TikTokFilled } from '@ant-design/icons';
import { Facebook, Instagram } from '@material-ui/icons';

const FooterDesktop: React.FC = () => {
    const { i18n } = useTranslation(); // Using useTranslation hook for static translations
    const { lng } = useParams<{ lng: string }>();
    useEffect(() => {
      if (lng && ['en', 'fr'].includes(lng)) {
        i18n.changeLanguage(lng);
      } else {
        // Handle unsupported language or redirect
        console.error('Unsupported language:', lng);
      }
    }, [lng, i18n])
    const [translatedTexts, setTranslatedTexts] = useState({
        address1: "Loading...",
        address2: "Loading...",
        phoneAuto: "Loading...",
        phoneMoto: "Loading...",
        email: "Loading...",
        hours: "Loading...",
        conditions: "Loading...",
        addressTitle: "Loading...",
        coordonneesTitle: "Loading...",
        horairesTitle: "Loading...",
        conditionsTitle: "Loading..."
    });

    useEffect(() => {
        const fetchTranslations = async () => {
            try {
                const address1 = await translateText('Cornavin (secrétariat et salle de cours) : rue Vallin 10, 1201 Genève', i18n.language);
                const address2 = await translateText('Eaux Vives (Leçons Conduite) : rue Ami Lullin 3, 1207 Genève', i18n.language);
                const phoneAuto = await translateText('Cours Auto : 078 892 86 84', i18n.language);
                const phoneMoto = await translateText('Cours moto : 078 892 86 84', i18n.language);
                const email = await translateText('info@success-ds.ch', i18n.language);
                const hours = await translateText('Lundi - samedi 10h - 18h', i18n.language);
                const conditions = await translateText('Consultez nos conditions générales', i18n.language);

                const addressTitle = await translateText('Adresse', i18n.language);
                const coordonneesTitle = await translateText('COORDONNÉES', i18n.language);
                const horairesTitle = await translateText('HORAIRES', i18n.language);
                const conditionsTitle = await translateText('Conditions générales', i18n.language);

                setTranslatedTexts({
                    address1,
                    address2,
                    phoneAuto,
                    phoneMoto,
                    email,
                    hours,
                    conditions,
                    addressTitle,
                    coordonneesTitle,
                    horairesTitle,
                    conditionsTitle
                });
            } catch (error) {
                console.error('Error fetching translations:', error);
            }
        };

        fetchTranslations();
    }, [i18n.language]);

    return (
        <Box className="footer" bgcolor="#f9f9f9" paddingY="15px">
            <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <Box textAlign="center">
                        <Typography variant="h6" className='footer__title' style={{ fontSize: '16px' }}>{translatedTexts.addressTitle}</Typography>
                        <Typography variant="body1" style={{ fontSize: '14px', fontWeight: 'bold' }}>- {translatedTexts.address1}</Typography>
                        <Typography variant="body1" style={{ fontSize: '14px', fontWeight: 'bold' }}>- {translatedTexts.address2}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Box textAlign="center">
                        <Typography variant="h6" className='footer__title' style={{ fontSize: '16px' }}>{translatedTexts.coordonneesTitle}</Typography>
                        <Typography variant="subtitle1" style={{ marginTop: '-20px', marginBottom: '-5px', fontSize: '14px' }} className='footer__title'>Tel.</Typography>
                        <Typography variant="body1" style={{ fontSize: '14px', fontWeight: 'bold' }}>{translatedTexts.phoneAuto}</Typography>
                        <Typography variant="body1" style={{ fontSize: '14px', fontWeight: 'bold' }}>{translatedTexts.phoneMoto}</Typography>
                        <Typography variant="subtitle1" className='footer__title' style={{ fontSize: '16px' }}>Email</Typography>
                        <Typography variant="body1" style={{ marginTop: '-10px', fontSize: '14px', fontWeight: 'bold', color: "#B827CE" }}>
                            <a href='mailto:info@success-ds.ch' style={{ color: 'white' }}>{translatedTexts.email}</a>
                        </Typography>
                        <Typography variant="subtitle1" className='footer__title' style={{ fontSize: '16px' }}>Socials</Typography>

                        <Typography variant="body2" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <WhatsAppIcon style={{ fontSize: '24px', margin: '0 10px', color: '#25D366' }} />
                        </a>
                        <a href="https://www.tiktok.com/@successdriving_gnva?_t=8nql6OkNRhY&_r=1" target="_blank" rel="noopener noreferrer">
                            <TikTokFilled style={{ fontSize: '24px', margin: '0 10px'}} />
                        </a>
                        <a href="https://www.instagram.com/success_driving_school_geneva?igsh=NzR0MTZ2bjFnaWlh&utm_source=qr" target="_blank" rel="noopener noreferrer">
                        <Instagram style={{ fontSize: '24px', margin: '0 10px', color: '#E1306C' }} />
                        </a>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <Facebook style={{ fontSize: '24px', margin: '0 10px', color: '#3b5998' }} />
                        </a>
                    </Typography>
                    </Box>
                   
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Box textAlign="center">
                        <Typography variant="h6" style={{ fontSize: '16px' }} className='footer__title'>{translatedTexts.horairesTitle}</Typography>
                        <Typography variant="body1" style={{ fontSize: '14px', fontWeight: 'bold' }}>{translatedTexts.hours}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Box textAlign="center">
                        <Typography variant="h6" style={{ fontSize: '16px' }} className='footer__title'>{translatedTexts.conditionsTitle}</Typography>
                        <Button
      component={Link}
      to={`/${i18n.language}/ConditionsGenerales`} // Dynamically set the language in the path
      style={{
        color: '#5881a9',
        fontSize: '14px',
        fontWeight: 'bold',
        textTransform: 'none', // Disable default uppercase transformation
      }}
    >
      {translatedTexts.conditions}
    </Button>
                    </Box>
                </Grid>

   
            </Grid>
            <Grid container justifyContent="center" spacing={3} style={{ marginTop: '20px' }}>
                
                <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="body2" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                        Copyright@ Success Driving School
                    </Typography>
                </Grid>
            </Grid>

        </Box>
    );
};

export default FooterDesktop;
