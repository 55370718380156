import React from 'react';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { makeStyles } from '@material-ui/core/styles';
import { CreditCardFilled } from '@ant-design/icons';
import { MdDoneOutline, MdRemoveRedEye } from 'react-icons/md';

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: 'rgb(184, 39, 206)',
  },
  completed: {
    backgroundColor: 'rgb(184, 39, 206)',
  },
});

const ColorlibStepIcon = (props: StepIconProps) => {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon />,
  
    2: <GroupAddIcon />,
    3: <MdRemoveRedEye />,
    4: <CreditCardFilled />
  };

  return (
    <div className={clsx(classes.root, {
      [classes.active]: active,
      [classes.completed]: completed,
    })}>
      {icons[String(props.icon)]}
    </div>
  );
};

export default ColorlibStepIcon;
