import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes, Navigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import PriceTableMoto from './PriceTableMoto';
import PriceTableITheorie from './PriceTableTheorie';
import FooterWrapper from './Footer';
import Home from './Home';
import Users from './Users';
import NotFound from './notfound';
import PricePage from './PricePage';
import CoursesPage from './CoursesPage';
import CoursListMOTO from './CoursListMOTO';
import AboutUs from './AboutUs';
import Contact from './Contact';
import PriceTablePack from './PriceTablePack';
import { Layout } from '@douyinfe/semi-ui';
import ToolbarComponent from './ToolbarComponent';
import MotoInfo from './motoInfo';
import MotoInfo1 from './motoInfo1';
import CoursesPageMoto from './CoursesPageMoto';
import CoursListMOTOMobile from './CoursListMOTOMobile';
import LanguageSwitcher from './LanguageSwitcher';
import BookingWizard from './BookingWizard';
import ConditionsGenerales from './ConditionsGenerales';
import TiserSlider from './TiserSlider';
import DetailPage from './DetailPage';
import PaymentSuccess from './PaymentSuccess';
import PackVoiture from './PackVoiture';
import DetailCours from './DetailCours';
import FirstAidComponent from './PremierSecours';
import Categories from './CoursList';
import FirstAid from './PremierSecours';
import Sensibiliation from './Sensibilisation';
import Theory from './Theory';
import ReservationSuccess from './ReservationSuccess';
import AutoPage from './AutoPage';
import MotoPage from './MotoPage';
import ScrollToTop from './ScrollToTop';
import MenuPanel from './MenuPanel';
import PriceTableTheorie from './PriceTableTheorie';
import LicenseExchangeInfo from './LicenceExchange';
import ViewPage from './ViewPackPage';
import DetailCoursView from './DetailsCoursView';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
  const { Header, Footer, Content } = Layout;
  const { i18n } = useTranslation();
  const { lng } = useParams<{ lng: string }>();

  // Update language based on the URL parameter
  useEffect(() => {
    if (lng) {
      console.log('Language parameter from URL:', lng);
      if (['en', 'fr'].includes(lng)) {
        i18n.changeLanguage(lng);
      } else {
        console.error('Unsupported language:', lng);
        // Optionally redirect to a default language
        // navigate('/en'); // Uncomment if you want to redirect to default language
      }
    }
  }, [lng, i18n]);
  return (
    <div className="App">
      <Router>
        <Layout>
          <ToastContainer />
          <Header>
            <ToolbarComponent />
          </Header>
          
          <Content>
          <ScrollToTop />

            <Routes>
              <Route path="/" element={<Navigate to={`/${i18n.language}`} />} />
              <Route path="/:lng" element={<Home />} />
              <Route path="/:lng/users" element={<Users />} />
              <Route path="/:lng/price" element={<PricePage />} />
              <Route path="/:lng/courses" element={<AutoPage />} />
              <Route path="/:lng/coursesMOTO" element={<CoursListMOTO />} />
              <Route path="/:lng/aboutUs" element={<AboutUs />} />
              <Route path="/:lng/contact" element={<Contact />} />
              <Route path="/:lng/PriceTablePack" element={<PriceTablePack />} />
              <Route path="/:lng/PriceTablePackVOITURE" element={<PackVoiture />} />
              <Route path="/:lng/moto-info" element={<MotoInfo />} />
              <Route path="/:lng/CoursesPageMoto" element={<MotoPage />} />
              <Route path="/:lng/TiserSlider" element={<TiserSlider />} />
              <Route path="/:lng/DetailPage" element={<ViewPage />} />
              <Route path="/:lng/DetailPageMobile/:packId" element={<ViewPage />} />
              <Route path="/:lng/ReservationCompleted" element={<PaymentSuccess />} />
              <Route path="/:lng/DetailCours/:packId" element={<DetailCoursView />} />
              <Route path="/:lng/FirstAid/:packId" element={<FirstAid />} />
              <Route path="/:lng/Sensibilisation/:packId" element={<Sensibiliation />} />
              <Route path="/:lng/Theory/:packId" element={<Theory />} />
              <Route path="/:lng/ReservationSuccess" element={<ReservationSuccess />} />
              <Route path="/:lng/Menu" element={<MenuPanel />} />
              <Route path="/:lng/CoursMoto" element={<PriceTableMoto />} />
              <Route path="/:lng/CoursAuto" element={<PriceTableTheorie />} />
              <Route path="/:lng/echange" element={<LicenseExchangeInfo />} />
              <Route path="/:lng/CoursListMOTOMobile" element={<CoursListMOTOMobile />} />
              <Route path="/:lng/ConditionsGenerales" element={<ConditionsGenerales />} />
              <Route path="/:lng/moto-info-1" element={<MotoInfo1 />} />
              <Route path="/:lng/booking-wizard" element={<BookingWizard />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Content>
          <Footer>
            <FooterWrapper />
          </Footer>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
