import React, { useEffect, useState } from 'react';
import { Typography, Card, CardContent } from '@material-ui/core';
import useStyles from './styles'; // Import styles
import moto from './images/moto_1.jpg'; // with import

interface ItemDetailsProps {
    lessonDetails: any; // Define a more specific type if you have one
}

const ItemDetails: React.FC<ItemDetailsProps> = ({ lessonDetails }) => {
    const classes = useStyles(); // Use styles
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [imageUrl, setImageUrl] = useState('https://local-fr-public.s3.eu-west-3.amazonaws.com/prod/webtool/userfiles/9593/Refonte/Agora-Auto-École-à-Seynod-.png');

    useEffect(() => {
        if (lessonDetails) {
            if (lessonDetails.type.includes('motos') || lessonDetails.name.includes('MOTO')) {
                setImageUrl(moto); // Set the image URL for motos
            } else {
                setImageUrl('https://local-fr-public.s3.eu-west-3.amazonaws.com/prod/webtool/userfiles/9593/Refonte/Agora-Auto-École-à-Seynod-.png'); // Set the original image URL
            }
        }
    }, [lessonDetails]);


    if (!lessonDetails) {
        return <Typography variant="h6">Loading...</Typography>;
    }

    return (
        <div className={classes.imageContainer}>
            <img
                className={classes.image}
                src={imageUrl}
                alt="Course Practical"
            />
            {isMobile ? <h3 className={classes.title}>
                {lessonDetails.name + "  -  "}
                {lessonDetails.promotionalPrice + " CHF "}
            </h3> : <h2 className={classes.title}>
                {lessonDetails.name + "  -  "}
                {lessonDetails.promotionalPrice + " CHF "}
            </h2>}
        </div>
    );
};

export default ItemDetails;
