import React, { useEffect, useState } from 'react';

import axios from 'axios';
import theory from './images/theorie.jpg'; // with import
import { useNavigate, useParams } from 'react-router-dom';
import CourseModule from './CourseBar';
import { Box, Typography, useMediaQuery, useTheme, Grid, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


interface Item {
    id: number;
    type: string;
    name: string;
    price: number;
    promotionalPrice: number;
    features: string[];
}
interface TheoryComponentProps {
  imgSrc: any;
  alt: string;
  name: string;
  desc: string | React.ReactNode;
  linkId: string;
  titre1: string;
  titre2?: string;
  desc2?: string;
  titre3?: string;
  desc3?: string;
  titre4?: string;
  desc4?: string;
  titre5?: string;
  desc5?: string;
  handleClick: any;
}

const TheoryComponent: React.FC<TheoryComponentProps> = ({
  imgSrc,
  alt,
  name,
  desc,
  titre1,
  desc2,
  titre3,
  desc3,
  titre4,
  desc4,
  titre5,
  desc5,
  handleClick,
}) => {
  const { t,i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className="categoryes__item" style={{ textAlign: 'left', padding: isMobile ? '20px' : '70px' }}>
      <Grid container spacing={3} className="container">
        <Grid item xs={12} md={3}>
          <Box className="categoryes__fig" style={{ paddingTop: isMobile ? '1px' : '15%', marginBottom: isMobile ? '5%' : '1px' }}>
            <img src={imgSrc} alt={alt} style={{ width: '100%', height: 'auto', borderRadius: '2px' }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="h4" className="categoryes__name" style={{ color: '#002d72', marginTop: isMobile ? '0' : '-5%' }}>
            {name}
          </Typography>
          <Typography variant="body1" className="categoryes__desc" style={{ color: 'black' }}>
            {desc}
          </Typography>
          <Typography variant="h5" style={{ color: 'rgb(0, 45, 114)', marginTop: '20px', fontSize: '23px' }}>
            {titre1}
          </Typography>
          <Typography variant="body1" className="categoryes__desc" style={{ color: 'black', fontSize: '17px' }}>
            {desc2}
          </Typography>
          <Grid container spacing={3} style={{ marginTop: '20px' }}>
            <Grid item xs={12} md={3}>
              <Typography variant="h5" style={{ color: 'rgb(0, 45, 114)', fontSize: '23px' }}>
                {titre3}
              </Typography>
              <Typography variant="body1" className="categoryes__desc" style={{ color: 'black', fontSize: '17px' }}>
                {desc3}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h5" style={{ color: 'rgb(0, 45, 114)', fontSize: '23px' }}>
                {titre4}
              </Typography>
              <Typography variant="body1" className="categoryes__desc" style={{ color: 'black', fontSize: '17px' }}>
                {desc4}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h5" style={{ color: 'rgb(0, 45, 114)', fontSize: '23px' }}>
                {titre5}
              </Typography>
              <Typography variant="body1" className="categoryes__desc" style={{ color: 'black', fontSize: '17px' }}>
                {desc5}
              </Typography>
            </Grid>
          </Grid>
          <Box style={{ marginTop: '20px', textAlign: isMobile ? 'center' : 'left' }}>
          <button className="btn popup-protect-btn" onClick={handleClick} style={{ fontSize: '16px', fontFamily: 'Helvetica' }}>
              {t('reserve')}
            </button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};



const Theory = () => {
    const { t,i18n } = useTranslation();
    const navigate=useNavigate();

    const { packId } = useParams<{ packId: string }>();

    const { lng } = useParams<{ lng: string }>();
    useEffect(() => {
      if (lng && ['en', 'fr'].includes(lng)) {
        i18n.changeLanguage(lng);
      } else {
        // Handle unsupported language or redirect
        console.error('Unsupported language:', lng);
      }
    }, [lng, i18n])
    const handleButtonClick = () => {
        navigate(`/${i18n.language}/DetailCours/${packId}`);
      
        };
    const [lessonDetails, setLessonDetails] = useState<Item | null>(null);
      const [payData, setPayData] = useState<number>(0);
      const [name, setName] = useState<string>("Theorie");

      useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page

          axios.get<Item>(`https://success-ds-geneva-66508d24e048.herokuapp.com/api/lessons/${packId}`)
              .then(response => {
                  setLessonDetails(response.data);
               //   price=response.data.promotionalPrice;
               setName(response.data.name)
                  setPayData(response.data.promotionalPrice); // Set payData here when lessonDetails is updated
              })
              .catch(error => console.error('Error fetching lesson details:', error));
      }, [packId]);

    return (
        <>
        <br/>
        <br/>
        <br/>
        <br/> <br/>
        <br/> <br/>
        <br/>
      <div className="categoryes">
  
  <TheoryComponent
        imgSrc={theory}
        alt={t('theory.alt')}
        name={t('theory.name')}
        desc={t('theory.description')}
        titre1={t('theory.title1')}
        desc2={t('theory.desc2')}
        titre3={t('theory.title3')}
        desc3={t('theory.desc3')}
        titre4={t('theory.title4')}
        desc4={t('theory.desc4')}
        titre5={t('theory.title5')}
        desc5=  {payData+ " CHF "}
        linkId='b'
        handleClick={handleButtonClick}
      />
      </div>
      </>
    );
  };
  
  export default Theory;