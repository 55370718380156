import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCar, faClock, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const BestFeatures = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile
  const { t,i18n } = useTranslation();

  return (
    <Box className="best">
      <Box className="container">
        <Box className="row">
          <Box className="best__title col-md-12">
            <Typography variant="h4" className="title" style={{ textAlign: 'left', marginBottom: '20px', fontSize: isMobile ? '25px' : "30.5px" }}>
              {t('bestTitle')} <span className="title__mark" style={{ color: '#B827CE', fontSize: isMobile ? '30px' : "40px" }}>{t('schoolName')}</span>
            </Typography>
          </Box>
          <Box component="ul" className="best__list" style={{ listStyleType: 'none', padding: 0 }}>
            <Box component="li" className="best__item" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="best__icon" style={{ marginTop:isMobile ? "-10%": "1px", marginLeft: isMobile ? '-55px' :'-30px',color: '#B827CE', marginRight: isMobile ? '30px' :'30px',fontSize: isMobile ? '30px' : "35px" }} />
              <Box>
                <Typography variant="body1" className="best__item-desc" style={{  fontSize: isMobile ? '20px' : "22px", marginRight: '40px' , color: "#E1E2E1", textAlign:'left'}} dangerouslySetInnerHTML={{ __html: t('bestLocation') }} />
              </Box>
            </Box>
            <Box component="li" className="best__item" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <FontAwesomeIcon icon={faCar} className="best__icon" style={{ marginTop:isMobile ? "-10%": "1px",marginLeft: isMobile ? '-55px' :'-30px',color: '#B827CE', marginRight: isMobile ? '25px' :'25px',fontSize: isMobile ? '28px' : "30px"  }} />
              <Box>
                <Typography variant="body1" className="best__item-desc" style={{  fontSize: isMobile ? '20px' : "22px", marginRight: '40px', color: "#E1E2E1", textAlign:'left' }} dangerouslySetInnerHTML={{ __html: t('bestCar') }} />
              </Box>
            </Box>
            <Box component="li" className="best__item" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <FontAwesomeIcon icon={faClock} className="best__icon" style={{ marginTop:isMobile ? "-10%": "1px",marginLeft: isMobile ? '-55px' :'-30px',color: '#B827CE', marginRight: isMobile ? '25px' :'25px',fontSize: isMobile ? '28px' : "30px"  }} />
              <Box>
                <Typography variant="body1" className="best__item-desc"style={{  fontSize: isMobile ? '20px' : "22px" , marginRight: '40px', color: "#E1E2E1", textAlign:'left'}} dangerouslySetInnerHTML={{ __html: t('bestSchedule') }} />
              </Box>
            </Box>
            <Box component="li" className="best__item" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <FontAwesomeIcon icon={faLanguage} className="best__icon" style={{ marginTop:isMobile ? "-10%": "1px",marginLeft:isMobile ? '-55px' : '-30px',color: '#B827CE', marginRight: isMobile ? '20px' :'25px',fontSize: isMobile ? '28px' : "30px"  }} />
              <Box>
                <Typography variant="body1" className="best__item-desc"style={{  fontSize: isMobile ? '20px' : "22px" , marginRight: '40px', color: "#E1E2E1", textAlign:'left'}} dangerouslySetInnerHTML={{ __html: t('bestLanguages') }} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BestFeatures;
