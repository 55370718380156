import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery, useTheme, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Adresse from './Adresse';
import AdresseMobile from './AdresseMobile';
import ToolbarComponent from './ToolbarComponent';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const Contact = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const { lng } = useParams();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [formStatus, setFormStatus] = useState({
    submitted: false,
    success: false,
    error: false,
  });

  const [loading, setLoading] = useState(false); // State to handle loading spinner

  useEffect(() => {
    if (lng && ['en', 'fr'].includes(lng)) {
      i18n.changeLanguage(lng);
    } else {
      console.error('Unsupported language:', lng);
    }
  }, [lng, i18n]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading spinner
    
    try {
      const response = await axios.post('https://success-ds-geneva-66508d24e048.herokuapp.com/api/contact/send', formData);
      if (response.status === 200) {
        setFormStatus({ submitted: true, success: true, error: false });
      } else {
        setFormStatus({ submitted: true, success: false, error: true });
      }
    } catch (error) {
      console.error('Error sending mail:', error);
      setFormStatus({ submitted: true, success: false, error: true });
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <div style={{ backgroundColor: '#FFF' }}>
      <ToolbarComponent />

      <div className="add-form">
        <div className="container">
          <h2 className="title" style={{ paddingTop: isMobile ? '23%' : '8%', fontSize: '30px' }}>
            <span className="title__mark">{t('informationTitle')}</span>
          </h2>
          <p style={{ paddingBottom: '40px', paddingRight: isMobile ? '2%' : '10%', paddingLeft: isMobile ? '2%' : '10%', lineHeight: '1.5', fontWeight: 400, fontSize: '18px' }}>
            {t('informationText')}
          </p>
          <form className="form" id="message_form" onSubmit={handleSubmit}>
            <div className="form__row row" style={{ marginRight: '0' }}>
              <div className="form__row-mobile col-md-6">
                <div className="control-group control-group_fullwidth">
                  <span className="control-remark control-group__item">
                    <FontAwesomeIcon icon={faUser} style={{ color: '#001a33', fontSize: '20px' }} />
                  </span>
                  <span className="inp">
                    <span className="inp__box">
                      <input
                        className="inp__control mand"
                        type="text"
                        name="name"
                        placeholder={t('firstNamePlaceholder')}
                        value={formData.name}
                        onChange={handleInputChange}
                        style={{ color: '#000000', fontSize: '20px', fontWeight: '500' }}
                      />
                    </span>
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="control-group control-group_fullwidth">
                  <span className="control-remark control-group__item">
                    <FontAwesomeIcon icon={faEnvelope} style={{ color: '#001a33', fontSize: '20px' }} />
                  </span>
                  <span className="inp">
                    <span className="inp__box">
                      <input
                        className="inp__control mand"
                        type="email"
                        name="email"
                        placeholder={t('emailPlaceholder')}
                        value={formData.email}
                        onChange={handleInputChange}
                        style={{ color: '#000000', fontSize: '20px', fontWeight: '500' }}
                      />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="form__row row" style={{ marginRight: '0' }}>
              <div className="col-md-12">
                <textarea
                  className="textarea textarea_fullwidth mand"
                  name="message"
                  placeholder={t('messagePlaceholder')}
                  value={formData.message}
                  onChange={handleInputChange}
                  style={{ color: '#000000', fontSize: '20px', fontWeight: '500' }}
                ></textarea>
              </div>
            </div>
            <div className="form__row row" style={{ marginRight: '0' }}>
              <div className="col-md-12" style={{ width: '100%' }}>
                <button className="btn btn_fullwidth" type="submit" style={{ width: isMobile ? '100%' : '30%' }}>
                  <span className="btn__text">{t('sendButton')}</span>
                </button>
              </div>
            </div>

            {loading && (
              <div className="form__row row" style={{ marginRight: '0', marginTop: '20px', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            )}

            {formStatus.submitted && (
              <div className="form__row row" style={{ marginRight: '0', marginTop: '20px' }}>
                {formStatus.success ? (
                  <div className="col-md-12">
                    <p style={{ color: 'green', fontSize: '18px' }}>{t('formSuccessMessage')}</p>
                  </div>
                ) : (
                  <div className="col-md-12">
                    <p style={{ color: 'red', fontSize: '18px' }}>{t('formErrorMessage')}</p>
                  </div>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
      {isMobile ? <AdresseMobile /> : <Adresse />}
    </div>
  );
};

export default Contact;
