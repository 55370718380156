import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactSection = () => {
    const { t,i18n } = useTranslation();

    return (
        <div style={{ display: 'flex', paddingTop: '40px' }}>
            <div style={{ marginLeft: '13%', width: '50%' }}>
                <div>
                    <h1 className="elementor-heading1">{t('contact.contact.heading')}</h1>
                </div>

                <div className="line-divider"></div><br />
                <div style={{ lineHeight: '1.5' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ fontWeight: 'bold', marginRight: '5px' }}>{t('contact.contact.secretariat_hours')}</h5>
                        <span style={{ fontWeight: 400 }}>{t('contact.contact.secretariat_hours_detail')}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ fontWeight: 'bold', marginRight: '5px' }}>{t('contact.contact.address')}</h5>
                        <span style={{ fontWeight: 400 }}>{t('contact.contact.address_detail')}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ fontWeight: 'bold', marginRight: '5px' }}>{t('contact.contact.email')}</h5>
                        <span style={{ fontWeight: 400 }}><a href="mailto:info@success-ds.ch">info@success-ds.ch</a></span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ fontWeight: 'bold', marginRight: '5px' }}>{t('contact.contact.phone')}</h5>
                        <span style={{ fontWeight: 400 }}><a href="tel:0225253839">078 892 86 84 / 022 525 38 39</a></span>
                    </div>
                </div>
            </div>
            <div style={{ width: '50%', marginRight: '9%', marginTop: '4%' }}>
                <div className="card11">
                    <header>
                        <time dateTime="2018-05-15T19:00" style={{ fontSize: "13px", marginLeft: "15px" }}>2024-2025</time>
                        <div className="logo11">
                            <span>
                                <svg width="120" height="20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M.2 .5L60 18 119.8 .5" fill="none" stroke="darkblue" strokeWidth="3" strokeMiterlimit="10" />
                                </svg>
                            </span>
                        </div>
                        <div className="sponsor" style={{ fontSize: "13px", marginRight: "14px" }}>{t('contact.general.geneva')}</div>
                    </header>
                    <div className="announcement">
                        <h1 style={{ fontFamily: "Asap Condensed", fontSize: "24px" }}>{t('contact.general.success_driving_school')}</h1>
                        <h3 style={{ fontStyle: "italic", fontSize: "20px" }}>{t('contact.general.school_no1')}</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSection;
