import React, { useState, useEffect } from 'react';
import { Toolbar, Button, makeStyles, AppBar, Typography } from '@material-ui/core';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import logo from './images/logo-nobackground-200.jpeg';
import { FaPhoneAlt } from "react-icons/fa";
import { FaMapMarkerAlt } from 'react-icons/fa';
import ModalComponent from './ModalComponent';
import SidebarComponent from './Sidebar';
import LanguageSwitcher from './LanguageSwitcher';
import translateText from './translate'; // Import your translateText function
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  spacer: {
    flexGrow: 1,
  },
  appBar: {
    height: 60, // Adjust height as needed
    zIndex: theme.zIndex.drawer + 1,
    background: 'white', // Set background color to white
  },
  logoContainer: {
    marginRight: theme.spacing(2),
  },
  logo: {
    height: 90, // Set the height of the logo
  },
  headerContact: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  headerCallback: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface Buttons {
  label: string;
  path: string;
}

const ToolbarComponent: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [translatedButtons, setTranslatedButtons] = useState<Buttons[]>([]);
  const { i18n } = useTranslation();
  const [activePath, setActivePath] = useState<string>(location.pathname); // State to track active path
  const { lng } = useParams<{ lng: string }>();
  useEffect(() => {
    if (lng && ['en', 'fr'].includes(lng)) {
      i18n.changeLanguage(lng);
    } else {
      // Handle unsupported language or redirect
      console.error('Unsupported language:', lng);
    }
  }, [lng, i18n]);
  // Define your buttons array with labels to translate
  const buttons: Buttons[] = [
    { label: "Accueil", path: "/" },
    { label: "À PROPOS", path: "/AboutUs/" },
    { label: "Cours", path: "/price/" },
    { label: "Auto", path: "/courses/" },
    { label: "Moto", path: "/coursesPageMOTO/" },
    { label: "Contact", path: "/Contact/" },
    { label: "Réserver", path: "/Menu" }, // Changed path to '#' to prevent navigation
  ];

  useEffect(() => {
    const fetchTranslations = async () => {
      const translatedLabels = await Promise.all(
        buttons.map(async (button) => {
          const translatedLabel = await translateText(button.label, i18n.language); // Translate each button label
          console.log(translatedLabel)

          return { ...button, label: translatedLabel }; // Update the label with translated text
        })
      );

      setTranslatedButtons(translatedLabels); // Update state with translated buttons
    };

    fetchTranslations();
  }, [i18n.language]);

  const handleNavigation = (path: string) => {
    if (path === "#") {
      setIsModalOpen(true); // Open modal when '#' is clicked
    } else {
      const lngPath = `/${i18n.language}${path}`;
      navigate(lngPath);
          setActivePath(path); // Set the active path on navigation
    }
  };


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen); // Toggle modal state
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Update mobile view state on resize
    };

    window.addEventListener('resize', handleResize); // Add event listener for resize

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up event listener on component unmount
    };
  }, []);

  return (
    <AppBar position="fixed" className={classes.appBar} style={{ height: "80px", backgroundColor: 'white' }}>
      <Toolbar>
      {isMobile ? (
          <SidebarComponent />
        ) : (
          <>
        <div className={classes.logoContainer} style={{ paddingRight: "5px", fontSize: '30px' }}>
          <img src={logo} alt="Logo" className={classes.logo} />
        </div>
        <div className={classes.headerContact}>
          <FaMapMarkerAlt style={{ color: 'blue', fontSize: '20px' }} />
          <Typography variant="body2" style={{ paddingLeft: "5px", paddingRight: "5px", fontSize: '21px' }}>
            10 Rue Vallin 1201 Genève
          </Typography>
        </div>
        <div className={classes.headerContact}>
          <FaPhoneAlt color='blue' style={{ paddingRight: "5px", fontSize: '22px' }} />
          <Typography variant="body2" style={{ fontSize: '21px' }}>022 525 38 39</Typography>
        </div>
        <div className={classes.headerCallback}>
          <ModalComponent
            isOpen={isModalOpen}
            onRequestClose={toggleModal}
          />
        </div>
        <div className={classes.spacer}></div>
        <LanguageSwitcher />
          {translatedButtons.map((button, index) => (
            <Button
              key={index}
              color="inherit"
              onClick={() => handleNavigation(button.path)}
              style={{
                color: button.path === activePath ? '#B827CE' : 'inherit',
                fontSize: index === translatedButtons.length - 1 ? '20px' : '15px',
                fontWeight: '600'
              }}
            >
              {button.label}
              </Button>
            ))}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default ToolbarComponent;
