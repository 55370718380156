// src/translate.ts
import axios from 'axios';

const API_URL = 'https://success-ds-geneva-66508d24e048.herokuapp.com/translate'; // URL of your Spring Boot backend

const translateText = async (text: string, targetLang: string): Promise<string> => {
  try {
    const response = await axios.get(API_URL, {
      params: {
        text: text,
        targetLang: targetLang
      }
    });

    // Assuming your backend responds with the translated text directly
    return response.data; // Adjust this based on your backend's response structure
  } catch (error) {
    console.error('Error translating text:', error);
    throw new Error('An error occurred while translating.');
  }
};

export default translateText;
