import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DirectionsCar, Favorite, Book, Warning, Motorcycle, AllInbox } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ModalComponent from './ModalComponent';
import { MdMultipleStop } from 'react-icons/md';

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 500,
        margin: 'auto',
        padding: theme.spacing(2),
        backgroundColor: '#f9f9f9',
        borderRadius: 10,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    custombtn: {
        padding: theme.spacing(1.5),
        '&:hover': {
            backgroundColor: '#9a23a6',
        },
    },
    icon: {
        marginRight: theme.spacing(1),
    },
}));

const MenuPanel = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const { lng } = useParams<{ lng: string }>();
    useEffect(() => {
      if (lng && ['en', 'fr'].includes(lng)) {
        i18n.changeLanguage(lng);
      } else {
        // Handle unsupported language or redirect
        console.error('Unsupported language:', lng);
      }
    }, [lng, i18n])
    const menuItems = [
        { label: t('timeline1.drivingLessons'), icon: <DirectionsCar className={classes.icon} />, index: 1 },
        { label: t('timeline1.firstAid'), icon: <Favorite className={classes.icon} />, index: 2 },
        { label: t('timeline1.theory'), icon: <Book className={classes.icon} />, index: 3 },
        { label: 'SENSIBILISATION', icon: <Warning className={classes.icon} />, index: 4 },
        { label: t('motorcycleCourseTitle'), icon: <Motorcycle className={classes.icon} />, index: 5 },
        { label: 'PACKS', icon: <AllInbox className={classes.icon} />, index: 6 },

        { label: t('licenseExchangeTitle'), icon: <MdMultipleStop className={classes.icon} />, index: 7 },

    ];

    const handleClick = (index) => {
        switch (index) {
            case 1:
                handleToggle()
                break;
            case 2:
                handleClickFirstAid();
                break;
            case 3:
                handleClickTheory();
                break;
            case 4:
                handleClickSensi();
                break;
            case 5:
                navigate(`/${i18n.language}/CoursMoto` );

                break;
            case 6:
                navigate(`/${i18n.language}/courses` );

                break;
            case 7:
                navigate(`/${i18n.language}/echange` );

                break;
            default:
                break;
        }
    };

    const handleClickTheory = () => {
        if (i18n.language === 'fr') {
            navigate(`/${i18n.language}/Theory/17`);
        } else {
            navigate(`/${i18n.language}/Theory/69`);
        }
    };

    const handleClickSensi = () => {
        if (i18n.language === 'fr') {
            navigate(`/${i18n.language}/Sensibilisation/16`);
        } else {
            navigate(`/${i18n.language}/Sensibilisation/67`);
        }
    };

    const handleClickFirstAid = () => {
        if (i18n.language === 'fr') {
            navigate(`/${i18n.language}/FirstAid/15`);
        } else {
            navigate(`/${i18n.language}/FirstAid/68`);
        }
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        handleToggle();
        // Autres actions à effectuer à la fermeture du modal
    };
    return (
        <>        <br />
            <br /><br /><br /><br /><br />
            <Card className={classes.card} style={{
                maxWidth: 500,
                margin: 'auto',
                backgroundColor: '#f9f9f9',
                borderRadius: 10,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                textAlign: 'center'
            }}>

                <CardContent>
                    <div className={classes.buttonGroup} style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        {menuItems.map((item, index) => (
                            <React.Fragment key={index}>
                                <Button
                                    className={classes.custombtn}
                                    style={
                                        {
                                            backgroundColor: '#B827CE',
                                            color: 'white',
                                            fontWeight: 'bold',

                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                        }
                                    }
                                    onClick={() => handleClick(item.index)}
                                    startIcon={item.icon}
                                >
                                    <Typography>{item.label}</Typography>
                                </Button>
                                {index < menuItems.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </div>
                </CardContent>
            </Card>
            <ModalComponent isOpen={isOpen} onRequestClose={handleClose} />

        </>
    );
};

export default MenuPanel;
