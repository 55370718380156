import React from 'react';


const ContactSection = () => {
    
    return (
        <div >
            <div style={{ marginLeft: '10%', marginRight:'10%' }}>
                <div>
                    <h1 className="elementor-heading1" style={{ marginLeft:'5%',fontWeight: 500 ,fontSize:"30px", color:'#B827CE', marginBottom:'5%' }} >COORDONNÉES</h1>
                </div>

                <div  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ fontWeight: 'bold', marginRight: '5px' , fontSize:'20px', marginLeft:'-8%'}}>Horaires du secrétariat:</h5>
                        <span style={{ fontWeight: 400, fontSize:'17px' }}>De 10 h à 17h30 du lundi au samedi</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ fontWeight: 'bold', marginRight: '5px', fontSize:'20px' }}>Adresse:</h5>
                        <span style={{ fontWeight: 400 , fontSize:'17px'}}>10 Rue Vallin 1201 Genève</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ fontWeight: 'bold', marginRight: '5px', fontSize:'20px' }}>Email:</h5>
                        <span style={{ fontWeight: 400, fontSize:'17px', marginLeft:'13%' }}><a href="info@success-ds.ch">info@success-ds.ch</a></span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h5 style={{ fontWeight: 'bold', marginRight: '5px' , fontSize:'20px'}}>Téléphone:</h5>
                        <span style={{ fontWeight: 400 , fontSize:'17px'}}><a href="tel:0040788928684">078 892 86 84 / 022 525 38 39</a> </span>
                    </div>
                </div>
            </div>
            <div style={{  marginRight: '15%', marginTop: '10%', marginBottom:'10%'}}>
                <div className="card11">
                    <header>
                        <time dateTime="2018-05-15T19:00" style={{ fontSize: "13px", marginLeft: "15px" }}>2024-2025</time>
                        <div className="logo11"> 
                            <span>
                                
                                <svg width="120" height="20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M.2 .5L60 18 119.8 .5" fill="none" stroke="darkblue" strokeWidth="3" strokeMiterlimit="10" />
                                </svg>
                            </span>
                        </div>
                        <div className="sponsor" style={{ fontSize: "13px", marginRight: "14px" }}>GENÈVE</div>
                    </header>
                    <div className="announcement">
                        <h1 style={{ fontFamily: "Asap Condensed", fontSize: "24px" }}>SUCCESS DRIVING SCHOOL</h1>
                        <h3 style={{ fontStyle: "italic", fontSize: "20px" }}>L'école de conduite N°1</h3>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default ContactSection;
