import {  createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(204, 153, 204)', // Change this to your desired color (yellow to orange)
    },
  },
});

export default theme;
