import React, { useState, useEffect } from 'react';
import {
    Button,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Grid,
    Divider,
    Card,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ColorlibStepIcon from './ColorlibStepIcon';
import PaymentForm from './PaymentForm';
import PaymentSuccess from './PaymentSuccess';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import useStyles from './styles'; // Import the styles
import DateStep from './Step0Date';
import InformationStep from './Step1Information';
import RecapStep from './Step2Recap';
import PaymentStep from './Step3Payment';
import ItemDetails from './ItemDetails';
import translateText from './translate';

interface Item {
    id: number;
    type: string;
    name: string;
    price: number;
    promotionalPrice: number;
    features: string[];
}


const stripePromise = loadStripe('pk_live_51ONheEGZGii0pkokVUFwkPeyfb6Knywm7HmOFXr0y5xPHLQBTJpVv9kEPtsrjZahcYJDEyEyUBe6aASe2TStjXMF00M2si45oS');

const ViewPage = () => {
    const { packId } = useParams<{ packId: string }>();
    const { t, i18n } = useTranslation();

    const { lng } = useParams<{ lng: string }>();
    useEffect(() => {
      if (lng && ['en', 'fr'].includes(lng)) {
        i18n.changeLanguage(lng);
      } else {
        // Handle unsupported language or redirect
        console.error('Unsupported language:', lng);
      }
    }, [lng, i18n]); 
    const navigate = useNavigate();
    const classes = useStyles(); // Use the styles
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        date: '',
        nom: '',
        prenom: '',
        dateNaissance: '',
        email: '',
        telephone: '',
        conditionsAcceptees: false,
        paymentMethod: '',
    });
    const [formErrors, setFormErrors] = useState({
        date: '',
        nom: '',
        prenom: '',
        dateNaissance: '',
        email: '',
        telephone: '',
        conditionsAcceptees: '',
        paymentMethod: '',
    });
    const [paymentSuccessful, setPaymentSuccessful] = useState(false);
    const [lessonDetails, setLessonDetails] = useState<Item | null>(null);
    const [payData, setPayData] = useState<Item | null>(null);
    const steps = ["Date", t('steps.1'), t('steps.2'), t('steps.3')];
    const [translatedFeatures, setTranslatedFeatures] = useState<string[]>([]);

    useEffect(() => {
        if (lessonDetails && lessonDetails.features && lessonDetails.features.length > 0) {
            const translateFeatures = async () => {
                const translatedResults: string[] = [];
    
                // Loop through each feature in lessonDetails.features
                for (const feature of lessonDetails.features) {
                    // Assuming splitFeatures splits a feature into multiple parts
                    for (const splitFeature of splitFeatures(feature)) {
                        const translatedFeature = await translateText(splitFeature, i18n.language);
                        translatedResults.push(translatedFeature);
                    }
                }
    
                setTranslatedFeatures(translatedResults);
            };
    
            translateFeatures();
        }
    }, [lessonDetails, i18n.language]);
    const splitFeatures = (feature: string) => {
        return feature
            .trim()
            .replace(/^- */, '')
            .split(/ - |\n/)
            .map(f => f.trim());
    };

    useEffect(() => {
        axios.get<Item>(`https://success-ds-geneva-66508d24e048.herokuapp.com/api/lessons/${packId}`)
            .then(response => {
                setLessonDetails(response.data);
                setPayData(response.data);
            })
            .catch(error => console.error('Error fetching lesson details:', error));
    }, [packId]);

    const handleNext = () => {
        if (validateForm()) {
            if (activeStep === 3 && formData.paymentMethod === 'cash') {
                createBooking();
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    };

    const validateForm = () => {
        let valid = true;
        const newFormErrors = { ...formErrors };

        switch (activeStep) {
            case 0:
                if (!formData.date) {
                    newFormErrors.date = 'Date is required';
                    valid = false;
                }
                break;
            case 1:
                if (!formData.nom) {
                    newFormErrors.nom = t('errors.nom');
                    valid = false;
                }
                if (!formData.prenom) {
                    newFormErrors.prenom = t('errors.prenom');
                    valid = false;
                }
                if (!formData.dateNaissance) {
                    newFormErrors.dateNaissance = t('errors.dateNaissance');
                    valid = false;
                }
                if (!formData.email) {
                    newFormErrors.email = t('errors.email');
                    valid = false;
                } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
                    newFormErrors.email = t('errors.emailInvalid');
                    valid = false;
                }
                if (!formData.telephone) {
                    newFormErrors.telephone = t('errors.telephone');
                    valid = false;
                } else if (!/^[0-9]+$/.test(formData.telephone)) {
                    newFormErrors.telephone = t('errors.telephoneInvalid');
                    valid = false;
                }
                if (!formData.conditionsAcceptees) {
                    newFormErrors.conditionsAcceptees = t('errors.conditionsAcceptees');
                    valid = false;
                }
                break;
            case 2:
                break;
            default:
                break;
        }

        setFormErrors(newFormErrors);
        return valid;
    };

    const createBooking = () => {
        const bookingData = {
            eleve: {
                nom: formData.nom,
                prenom: formData.prenom,
                dateNaissance: formData.dateNaissance,
                email: formData.email,
                telephone: formData.telephone,
            },
            lessonId: packId,
            bookingDate: formData.date,
            typePayment: 'ESPECES',
            status: 'INPROGRESS'
        };

        axios.post('https://success-ds-geneva-66508d24e048.herokuapp.com/api/bookings', bookingData)
            .then(response => {
                console.log('Booking created successfully:', response.data);
                toast.success('Réservation prise en compte');
                navigate("/ReservationSuccess");
            })
            .catch(error => {
                console.error('Error creating booking:', error);
                toast.error('Erreur lors de la réservation, vérifiez vos entrées.');
            });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setFormData({
            date: '',
            nom: '',
            prenom: '',
            dateNaissance: '',
            email: '',
            telephone: '',
            conditionsAcceptees: false,
            paymentMethod: '',
        });
    };

    const handleInputChange = (prop: keyof typeof formData) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [prop]: event.target.value });

        if (formErrors[prop]) {
            setFormErrors({ ...formErrors, [prop]: '' });
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.checked });

        if (formErrors.conditionsAcceptees) {
            setFormErrors({ ...formErrors, conditionsAcceptees: '' });
        }
    };

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <DateStep formData={formData} formErrors={formErrors} handleInputChange={handleInputChange} />;
            case 1:
                return <InformationStep formData={formData} formErrors={formErrors} handleInputChange={handleInputChange} handleCheckboxChange={handleCheckboxChange} />;
            case 2:
                return <RecapStep formData={formData} t={t} />;
            case 3:
                return <PaymentStep formData={formData} handleInputChange={handleInputChange} payData={payData} setPaymentSuccessful={setPaymentSuccessful} />;
            default:
                return 'Étape inconnue';
        }
    };

    return (
        <>
<br/><br/>
<br/><br/>
<br/>
            <div className={classes.container}>
                <ItemDetails lessonDetails={lessonDetails} />
                <div className="container" >
                    <div className="col-md-7" style={{ width: '95%', marginLeft: '2%' }}>
                        <div style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", padding: "20px" }} >
                            <div className="about__body-inner" >
                                <div style={{ textAlign: 'center' }}>
                                    <h2 className="title" style={{ color: '#002d72', fontWeight: 'bold', font: '1.7rem Montserrat', padding: '10px', fontSize: '27px', marginTop: "1%" }}>
                                        <span className="title__mark" >{t('programme.title')} </span>
                                    </h2>
                                    <div>
                                        {translatedFeatures.map((feature, featureIndex) => (
                                            <h3 key={featureIndex} style={{ display: 'flex', alignItems: 'center', fontSize: '15px', }}>
                                                <span className="title__mark">
                                                    -  {feature}
                                                </span>
                                            </h3>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className="container" >
                    <div className="col-md-7" style={{ width: '95%', marginLeft: '2%' }}>
                        <div style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", padding: "20px" }} >
                            <div className="about__body-inner" >
                                <div style={{ textAlign: 'center' }}>
                                    <h2 className="title" style={{ color: '#002d72', fontWeight: 'bold', font: '1.7rem Montserrat', padding: '10px', fontSize: '27px', marginTop: "1%" }}>
                                        <span className="title__mark" >{t('scheduleProgram.title')} </span>
                                    </h2>
                                    <div>
                                        <h3 style={{ display: 'flex', alignItems: 'center', fontSize: '20px', marginTop: "4%", marginBottom: "2%", marginLeft: '5%' }}>
                                            <span className="title__mark">{t('scheduleProgram.reservationInstructions')}</span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.stepperContainer}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography>
                                Toutes les étapes sont terminées. Paiement effectué avec succès.
                            </Typography>
                            <Button onClick={handleReset} className={classes.buttonColor}>
                                Réinitialiser
                            </Button>
                        </div>
                    ) : (
                        <div className={classes.centeredContainer}>
                        <Card className={classes.centeredcard} style={{ maxWidth: "80%", justifyContent: "center" }}>
                                {getStepContent(activeStep)}
                            </Card>
                            <Grid container spacing={2} className={classes.buttonContainer}>
                                <Grid item>
                                    <button disabled={activeStep === 0} onClick={handleBack} className="btn popup-protect-btn" style={{ backgroundColor: "gray" }} >

                                        {t('buttons.back')}
                                    </button>
                                </Grid>
                                <Divider orientation='vertical'></Divider>

                                <Grid item>
                                { (activeStep != steps.length - 1 || formData.paymentMethod === "cash") && 
                                    <button onClick={handleNext} className="btn popup-protect-btn">

                                        {activeStep === steps.length - 1 ? t('buttons.confirm') : t('buttons.next')}
                                    </button>}
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ViewPage;
