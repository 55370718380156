import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faHandshake, faClipboard, faBook, faUsers, faLightbulb, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'; // Importez les icônes nécessaires
import ModalComponent from './ModalComponent'; // Assurez-vous que vous avez un composant de modale

const MotoInfoC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleButtonClick = () => {
        // Logique supplémentaire si nécessaire avant la navigation
        console.log("Clic sur le bouton 'Nous contacter'");
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="container">
                <div className="col-md-7" style={{ width: '95%', marginLeft: '40px', marginTop: "10%" }}>
                    <div className="about__body1">
                        <div className="about__body-inner">
                            <div style={{ textAlign: 'center' }}>
                                <h2 className="title" style={{ color: '#002d72', fontWeight: 'bold', font: '1.7rem Montserrat', padding: '10px', fontSize: '27px' }}>
                                    <span className="title__mark">Cours pratique de 12H</span>
                                </h2>
                                <div>
                                    <h3 style={{ display: 'flex', alignItems: 'center', fontSize: '20px', marginTop: "4%", marginBottom: "2%" }}>
                                        <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: 'red', marginRight: '15px', fontFamily: 'Montserrat', fontSize: '2em' }} />
                                        <span className="title__mark">Cours pratique obligatoire pour passer à l'examen pratique</span>
                                    </h3>
                                </div>
                                <div>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px', marginBottom: "1%" }}>
                                        Date:
                                        <span style={{ marginLeft: "10px", color: '#000', fontSize: '20px' }}>
                                            Du 28 avril au 30 avril
                                        </span>
                                    </h3>
                                </div>
                                <div>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                                        Durée:
                                        <span style={{ marginLeft: "10px", color: '#000', fontSize: '20px' }}>
                                            🔸 12H
                                        </span>
                                    </h3>
                                   
                                </div>
                                <div>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px', marginBottom: "1%" }}>
                                        Tarif:
                                        <span style={{ marginLeft: "10px", color: '#000', fontSize: '20px' }}>
                                            300 CHF (12 heures en groupe)
                                        </span>
                                    </h3>
                                </div>
                                <div>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px', marginBottom: "1%" }}>
                                        Langue:
                                        <span style={{ marginLeft: "10px", color: '#000', fontSize: '20px' }}>
                                            Français
                                        </span>
                                    </h3>
                                </div>
                                <div>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                                        Conditions:
                                        <span style={{ marginLeft: "10px", color: '#000', fontSize: '20px' }}>
                                            🔺 Équipements requis (obligatoire)
                                        </span>
                                    </h3>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                                        <span style={{ marginLeft: "115px", color: '#000', fontSize: '20px' }}>
                                            🔺 Permis élève A ou A1 (obligatoire)
                                        </span>
                                    </h3>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                                        <span style={{ marginLeft: "115px", color: '#000', fontSize: '20px' }}>
                                            🔺 Permis du véhicule (obligatoire)
                                        </span>
                                    </h3>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                                        <span style={{ marginLeft: "115px", color: '#000', fontSize: '20px' }}>
                                            🔺 Véhicule non fourni
                                        </span>
                                    </h3>
                                </div>
                                <button className="btn popup-protect-btn" onClick={handleButtonClick} style={{ marginBottom:"20px", marginTop:'3%' }}>
                                    <span className="btn__text">Réserver</span>
                                    <div className="data-form" data-title="Eliminating fear and excitement behind the wheel" data-duration="30 days" data-icon="" data-img="https://drivingschool.wpmix.net/wp-content/uploads/2017/04/card3-262x262.jpg"></div>
                                </button>
                                <ModalComponent isOpen={isOpen} onRequestClose={handleClose} />
                                
                            </div>
                            <Link to="/coursesMOTO">
                                    <button style={{ marginTop: '10px' }}>
                                        Retour
                                    </button>
                                </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MotoInfoC;
