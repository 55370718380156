import React from 'react';
import PriceTableITheorie from './PriceTableTheorie';
import PriceTableConduite from './PriceTableConduite';
import PriceTablePack from './PriceTablePack';
import PriceTableMoto from './PriceTableMoto';

import ToolbarComponent from './ToolbarComponent';
import CourseTabs from './CourseTab';
import CourseTabSelect from './CourseTabSelect';

const MotoPage = () => {
    return (
        <div>
            <div className="price price_index" style={{paddingTop: '50px', paddingBottom: '100px'}}>
                <br/>
                <br/>
                <br/>

            <CourseTabSelect type="moto" />

            {/*<h1 className='price-section'>Tarifs</h1>*/}
           
            </div>
        </div>
    );
};

export default MotoPage;
