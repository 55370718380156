import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Stepper,
    Step,
    StepLabel,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Card
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SessionSelector from './SessionSelector';
import UserInfoForm from './UserInfoForm';
import Recap from './Recap';
import PaymentOptions from './PaymentOptions';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import translateText from './translate';
import { useStyless } from './styles';
import axios from 'axios';
import { SessionDetail } from './types';
import StepData from './StepContent';
import moto from './images/moto_1.jpg'; // with import

interface Item {
    id: number;
    type: string;
    name: string;
    price: number;
    promotionalPrice: number;
    features: string[];
    sessions: any[];
}

const formatSessionDetails = async (details, lng) => {
    const regex = /(\d+(?:ère|ème)? PARTIE)\s+(\w+)\s+(\d{2}\/\d{2}\/\d{4})\s+(\d{2}H\d{2}) à (\d{2}H\d{2})\s+(.+?)(?=\d+(?:ère|ème)? PARTIE|$)/g;
    console.log(details);
    const sessions: SessionDetail[] = [];

    let match;
    while ((match = regex.exec(details)) !== null) {
        console.log(match)
        const partTitle = match[1].trim();
        const dayOfWeek = match[2].trim();
        const date = match[3];
        const startTime = match[4];
        const endTime = match[5];
        const location = match[6].trim();

        let translatedTitle = '';
        let dateTitle = "Date";
        let dateText = `${dayOfWeek} `;
        let dateVal = `${date}`;
        let heureTitle = "Heure";
        let heureText = `${startTime} à ${endTime}`;
        let lieuTitle = "Lieu";

        try {
            translatedTitle = await translateText(partTitle, lng);
            dateTitle = await translateText("Date", lng);
            dateText = await translateText(dateText, lng);
            heureTitle = await translateText("Heure", lng);
            heureText = await translateText(heureText, lng);
            lieuTitle = await translateText("Lieu", lng);
        } catch (error) {
            console.error(`Error translating text:`, error);
            translatedTitle = partTitle; // Use original title if translation fails
        }

        sessions.push({
            title: translatedTitle,
            date: {
                title: dateTitle,
                value: dateText + dateVal
            },
            time: {
                title: heureTitle,
                value: heureText
            },
            location: {
                title: lieuTitle,
                value: location
            }
        });
    }

    return sessions;
};

const splitFeatures = (feature: string) => {
    return feature
        .replace(/^- */, '')
        .split(/ - |\n/)
        .map(f => f.trim());
};

const getMonthYear = (sessionDetails, language) => {
    const parts = sessionDetails.split(/\s+/);
    const dateString = parts.find(part => /\d{2}\/\d{2}\/\d{4}/.test(part));

    if (!dateString) {
        console.error('Date string not found in session details:', sessionDetails);
        return '';
    }

    const [day, month, year] = dateString.split('/');
    const date = new Date(year, month - 1, day);
    const formattedDate = new Intl.DateTimeFormat(language, { month: 'long', year: 'numeric' }).format(date);

    return formattedDate;
};

const DetailCoursView = () => {
    const { packId } = useParams<{ packId: string }>();
    const { t, i18n } = useTranslation();

    const { lng } = useParams<{ lng: string }>();
    useEffect(() => {
      if (lng && ['en', 'fr'].includes(lng)) {
        i18n.changeLanguage(lng);
      } else {
        // Handle unsupported language or redirect
        console.error('Unsupported language:', lng);
      }
    }, [lng, i18n])
    const classes = useStyless();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    
    const [activeStep, setActiveStep] = useState(0);
    const [translatedFeatures, setTranslatedFeatures] = useState<string[]>([]);
    const [formData, setFormData] = useState({
        session: '',
        nom: '',
        prenom: '',
        dateNaissance: '',
        email: '',
        telephone: '',
        numeroregister: '',
        conditionsAcceptees: false,
        paymentMethod: '',
    });
    const [paymentSuccessful, setPaymentSuccessful] = useState(false);
    const [lessonDetails, setLessonDetails] = useState<Item | null>(null);
    const [payData, setPayData] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [formattedSession, setFormattedSession] = useState<SessionDetail[]>([]);
    const [formErrors, setFormErrors] = useState({
        session: '',
        nom: '',
        prenom: '',
        dateNaissance: '',
        email: '',
        telephone: '',
        numeroregister: '',
        adresse: '',
        conditionsAcceptees: '',
        paymentMethod: '',
    });

    const [isCodeValid, setIsCodeValid] = useState(true);
    const steps = [
        t('steps.0'),
        t('steps.1'),
        t('steps.2'),
        t('steps.3')
    ];

    useEffect(() => {
        axios.get(`https://success-ds-geneva-66508d24e048.herokuapp.com/api/lessons/${packId}`)
            .then(response => {
                setLessonDetails(response.data);
                setPayData(response.data);
                if (response.data.name.includes('AID') || response.data.name.includes('SECOURS')) {
                    setImageUrl('https://someurl.com/secours.jpg');
                } else if (response.data.name.includes('SENSIBILISATION')) {
                    setImageUrl('https://www.auto-ecole-europeenne.be/wp-content/uploads/2016/09/Sea-cours-theoriques-Auderghem.jpg');
                } else if (response.data.type.includes('motos') || response.data.name.includes('MOTO')) {
                    setImageUrl(moto);
                }else {
                    setImageUrl('https://sergio-auto-ecole.ch/site/wp-content/uploads/2022/02/Sensi.jpg');

                }
            })
            .catch(error => console.error('Error fetching lesson details:', error));
    }, [packId]);

    useEffect(() => {
        const fetchFormattedSession = async () => {
            if (formData.session) {
                try {
                    const sessions = await formatSessionDetails(formData.session, i18n.language);
                    const updatedSessions = sessions.map(session => ({
                        ...session,
                        location: {
                            ...session.location,
                            value: session.location.value.replace(/AGENCE CORNAVIN BEL AIR/g, 'AGENCE CORNAVIN BEL AIR<br/>')
                        }
                    }));
                    setFormattedSession(updatedSessions);
                } catch (error) {
                    console.error('Error formatting session details:', error);
                    setFormattedSession([]);
                }
            }
        };

        fetchFormattedSession();
    }, [formData.session, i18n.language]);

    useEffect(() => {
        if (lessonDetails && lessonDetails.features && lessonDetails.features.length > 0) {
            const translateFeatures = async () => {
                const translatedResults: string[] = [];
    
                // Loop through each feature in lessonDetails.features
                for (const feature of lessonDetails.features) {
                    // Assuming splitFeatures splits a feature into multiple parts
                    for (const splitFeature of splitFeatures(feature)) {
                        const translatedFeature = await translateText(splitFeature, i18n.language);
                        translatedResults.push(translatedFeature);
                    }
                }
    
                setTranslatedFeatures(translatedResults);
            };
    
            translateFeatures();
        }
    }, [lessonDetails, i18n.language]);

    const handleCodeValidation = (code) => {
        return code === 'VALID_CODE';
    };

    const handleNext = () => {

        if (validateForm()) {
            if (activeStep === steps.length - 1) {
                setPaymentSuccessful(true);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
        
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setFormData({
            session: '',
            nom: '',
            prenom: '',
            dateNaissance: '',
            email: '',
            telephone: '',
            numeroregister: '',
            conditionsAcceptees: false,
            paymentMethod: '',
        });
    };

    const handleInputChange = (field) => (event) => {
        setFormData({ ...formData, [field]: event.target.value });
    };

    const handleCheckboxChange = (event) => {
        setFormData({ ...formData, conditionsAcceptees: event.target.checked });
    };

    const isConfirmerDisabled = formData.paymentMethod === 'code' && !isCodeValid;


    const validateForm = () => {
        let valid = true;
        const newFormErrors = { ...formErrors };

        // Validate based on the active step
        switch (activeStep) {
            case 0: // Step 1: Date et Billet
                if (!formData.session) {
                    newFormErrors.session = t('errors.session');
                    valid = false;
                }
                break;
            case 1: // Step 2: Informations
                if (!formData.nom) {
                    newFormErrors.nom = t('errors.nom');
                    valid = false;
                }
                if (!formData.prenom) {
                    newFormErrors.prenom = t('errors.prenom');
                    valid = false;
                }
                if (!formData.dateNaissance) {
                    newFormErrors.dateNaissance = t('errors.dateNaissance');
                    valid = false;
                }
                if (!formData.email) {
                    newFormErrors.email = t('errors.email');
                    valid = false;
                } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
                    newFormErrors.email = t('errors.emailInvalid');
                    valid = false;
                }
                if (!formData.telephone) {
                    newFormErrors.telephone = t('errors.telephone');
                    valid = false;
                } else if (!/^[0-9]+$/.test(formData.telephone)) {
                    newFormErrors.telephone = t('errors.telephoneInvalid');
                    valid = false;
                }


                if (lessonDetails && (lessonDetails.name.includes('SENSIBILISATION') || lessonDetails.type.includes('Leçons motos'))) {
                    if (!formData.numeroregister) {
                        newFormErrors.numeroregister = t('errors.numeroregister');
                        valid = false;
                    }
                }


                if (!formData.conditionsAcceptees) {
                    newFormErrors.conditionsAcceptees = t('errors.conditionsAcceptees');
                    valid = false;
                }
                break;
            case 2: // Step 3: Recap
                // No additional validation for Recap step
                break;
            default:
                break;
        }

        setFormErrors(newFormErrors);
        return valid;
    };
    return (
        <div>
            {lessonDetails === null ? (
                <CircularProgress />
            ) : (
                <>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>

                    <div className={classes.imageContainer}>
                        <img className={classes.image} src={imageUrl} alt="Course Practical" />
                        {isMobile ? (
                            <h3 className={classes.title}>
                                {lessonDetails.name + "  A  "}
                                {lessonDetails.promotionalPrice + " CHF "}
                            </h3>
                        ) : (
                            <h2 className={classes.title}>
                                {lessonDetails.name + "  A  "}
                                {lessonDetails.promotionalPrice + " CHF "}
                            </h2>
                        )}
                    </div>
                    <br />
                    <br />
                    <div className="container">
                        <div className="col-md-7" style={{ width: '95%', marginLeft: '2%' }}>
                            <div style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", padding: "20px" }} >
                                <div className="about__body-inner" >
                                    <div style={{ textAlign: 'center' }}>
                                        <h2 className="title" style={{ color: '#002d72', fontWeight: 'bold', font: '1.7rem Montserrat', padding: '10px', fontSize: '27px', marginTop: "1%" }}>
                                            <span className="title__mark">{t('programme.title')}</span>
                                        </h2>
                                        <div>
                                            {translatedFeatures.map((feature, featureIndex) => (
                                                <h3 key={featureIndex} style={{ display: 'flex', alignItems: 'center', fontSize: '15px', }}>
                                                    <span className="title__mark">
                                                        -  {feature}
                                                    </span>
                                                </h3>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.stepperContainer}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>

                    <div className={classes.centeredContainer}>
                        <Card className={classes.centeredcard} style={{ maxWidth: "100%", justifyContent: "center" }}>

                            <StepData
                                step={activeStep}
                                lessonDetails={lessonDetails}
                                formData={formData}
                                setFormData={setFormData}
                                formErrors={formErrors}
                                handleInputChange={handleInputChange}
                                handleCheckboxChange={handleCheckboxChange}
                                payData={payData}
                                setPaymentSuccessful={setPaymentSuccessful}
                                classes={classes}
                                formattedSession={formattedSession}
                                t={t}
                                isCodeValid={isCodeValid}
                                handleCodeValidation={handleCodeValidation}
                            />

                            <div className={classes.buttonContainer}>
                           { !paymentSuccessful &&
                                <button disabled={activeStep === 0} onClick={handleBack} className="btn popup-protect-btn" style={{ backgroundColor: "gray" }}>
                                    Retour
                                </button>}
                                <Divider orientation='vertical'></Divider>
                                { activeStep != steps.length - 1 && 
                                <button onClick={handleNext} className="btn popup-protect-btn" disabled={isConfirmerDisabled}>
                                    {activeStep === steps.length - 1 ? 'Confirmer' : 'Suivant'}
                                </button>}
                            </div>
                        </Card>
                    </div>
                
                </>
            )}
        </div>
    );
};

export default DetailCoursView;
