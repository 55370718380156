import React, { Component, useEffect } from 'react';
import { Typography, Button, Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// Styles
const useStyles = makeStyles({
    button: {
        marginTop: "40%",
        width: "200px",
        height: "50px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
        backgroundColor: '#B827CE',
        color: 'white',
        fontSize: '18px',
        fontFamily: 'Arial',
        fontWeight: 600,
        textTransform: 'uppercase',
        borderRadius: '5px',
    },
});

const CustomButton = () => {
    const classes = useStyles();

    return (
        <Button
            variant="contained"
            color="primary"
            className={`${classes.button} btn header__callback`}
        >
            <Typography style={{ fontWeight: 550 }}>Réserver</Typography>
        </Button>
    );
};

interface TiserSliderState {
    isOpenDriving: boolean;
    selectedOption: any;
    selectedOptionDriving: any;
    options: any[];
    optionsDriving: any[];
}

interface TiserSliderProps {
    isMobile: boolean;
    translation: any;
    lng: any;

    navigate: any; // add navigate as a prop
}

class TiserSlider extends Component<TiserSliderProps, TiserSliderState> {
    constructor(props: TiserSliderProps) {
        super(props);
        this.state = {
            isOpenDriving: false,
            selectedOption: null,
            selectedOptionDriving: null,
            options: [],
            optionsDriving: [],
        };
    }

    handleOptionClick = (option: any) => {
        this.setState({
            selectedOption: option,
            isOpenDriving: false,
        });
    };

    render() {
        const { isOpenDriving } = this.state;
        const { isMobile, translation,lng, navigate } = this.props; // extract navigate from props

        const toggle = () => {
            navigate("/Menu");
            navigate(`/${lng}/Menu`);

        };

        return (
            <Box className="tiser" position="relative" display="block" overflow="hidden" bgcolor="#383838">
                <Box
                    id="tiserSlider"
                    className="swiper-container swiper-container-initialized swiper-container-horizontal"
                    component="div"
                >
                    <Box className="tiser__slide swiper-slide swiper-slide-active" component="div">
                        <Box className="tiser__body">
                            <Box
                                className="tiser__body-inner"
                                style={{
                                    backgroundColor: '#FFF',
                                    padding: '20px',
                                    borderRadius: '20px',
                                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                                    maxWidth: '940px',
                                    margin: '0 auto',
                                    maxHeight: isMobile ? '210px' : '150px'
                                }}
                            >
                                <Box className="tiser__title-wrap">
                                    <Typography
                                        variant="h5"
                                        style={{ fontSize: isMobile ? '30px' : '40px', color: '#B827CE', fontFamily: 'Arial', fontWeight: 700 }}
                                    >
                                        {translation('bigTitle')}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <button className="btn popup-protect-btn" onClick={toggle} style={{ marginTop: '20%' }}>
                                    <span className="btn__text" style={{ fontSize: '18px', paddingTop: '10px', paddingBottom: '30px', fontWeight: 700 }}>
                                        {translation('reserve')}
                                    </span>
                                </button>
                            </Box>
                        </Box>
                    </Box>
                    <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                </Box>
            </Box>
        );
    }
}

// Wrapper component to handle hooks
const TiserSliderWrapper = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t,i18n } = useTranslation();

    const { lng } = useParams<{ lng: string }>();
    useEffect(() => {
      if (lng && ['en', 'fr'].includes(lng)) {
        i18n.changeLanguage(lng);
      } else {
        // Handle unsupported language or redirect
        console.error('Unsupported language:', lng);
      }
    }, [lng, i18n]); 
    const navigate = useNavigate(); // get navigate function from useNavigate hook

    return <TiserSlider isMobile={isMobile} translation={t} lng={lng} navigate={navigate} />; // pass navigate as a prop
};

export default TiserSliderWrapper;
