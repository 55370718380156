import { Typography } from '@material-ui/core';
import React from 'react';

const SessionPres = ({ formattedSessions }) => {
    return (
        <div>
            {formattedSessions.map((session, index) => (
                    <p>
                        📆  {session.date.value} 
                    </p>
                  
            ))}
        </div>
    );
};

export default SessionPres;
