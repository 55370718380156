// Step3Payment.tsx
import React from 'react';
import { Grid } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import mastercardImage from './images/icons8-mastercard-48.png';
import cashImage from './images/icons8-en-espèces-48.png';
import useStyles from './styles'; // Adjust the import path as necessary
import stripePromise from './stripeP';

export interface FormData {
    date: string;
    nom: string;
    prenom: string;
    dateNaissance: string;
    email: string;
    telephone: string;
    conditionsAcceptees: boolean;
    paymentMethod: string;
}

interface Step3PaymentProps {
    formData: { paymentMethod: string };
    handleInputChange: (prop: keyof FormData) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    payData: any;
    setPaymentSuccessful: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaymentStep: React.FC<Step3PaymentProps> = ({ formData, handleInputChange, payData, setPaymentSuccessful }) => (
    <div>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <div className={`rent-radio ${formData.paymentMethod === 'card' ? 'active' : ''}`}>
                    <label className="custom_radio">
                        <div className="radio-content">
                            <img src={mastercardImage} alt="Mastercard" className="radio-image" />
                            <span className="rent-option">Payer par carte</span>
                        </div>
                        <input
                            type="radio"
                            className="rent-types"
                            name="paymentMethod"
                            value="card"
                            checked={formData.paymentMethod === 'card'}
                            onChange={handleInputChange('paymentMethod')}
                            required
                        />
                        <span className="checkmark-circle" />
                    </label>
                </div>
            </Grid>

            <Grid item xs={6}>
                <div className={`rent-radio ${formData.paymentMethod === 'cash' ? 'active' : ''}`}>
                    <label className="custom_radio">
                        <div className="radio-content">
                            <img src={cashImage} alt="Cash" className="radio-image" />
                            <span className="rent-option">Payer en espèces</span>
                        </div>
                        <input
                            type="radio"
                            className="rent-types"
                            name="paymentMethod"
                            value="cash"
                            checked={formData.paymentMethod === 'cash'}
                            onChange={handleInputChange('paymentMethod')}
                            required
                        />
                        <span className="checkmark-circle" />
                    </label>
                </div>
            </Grid>
        </Grid>

        {formData.paymentMethod === 'card' && (
            <div>
                <Elements stripe={stripePromise}>
                <PaymentForm formData={formData} payData={payData} setPaymentSuccessful={setPaymentSuccessful} />
                </Elements>
            </div>
        )}
    </div>
);

export default PaymentStep;
