import React, { useCallback, useEffect, useState } from 'react';
import { EmbeddedCheckoutProvider, EmbeddedCheckout, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import stripePromise from './stripeP';
import { toast } from 'react-toastify';

interface PaymentFormProps {
  formData: any;
  setPaymentSuccessful: (successful: boolean) => void;
  payData: any;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ formData, setPaymentSuccessful, payData }) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [bookingId, setBookingId] = useState<string | null>(null);

  const stripe = useStripe();
  const elements = useElements();
  const [dialogOpen, setDialogOpen] = useState(false); // Local state to control dialog visibility

  const fetchClientSecret = useCallback(async () => {
    const bookingData = {
      eleve: {
        nom: formData.nom,
        prenom: formData.prenom,
        dateNaissance: formData.dateNaissance,
        email: formData.email,
        telephone: formData.telephone,
        adresse: formData.adresse,
        numeroregister: formData.numeroregister,
      },
      lessonId: payData.id,
      bookingDate: formData.date,
      sessionChoosed: {
        sessionDetails: formData.session,
      },
      status: 'PENDING',
    };

    try {
      const response = await axios.post(
        "https://success-ds-geneva-66508d24e048.herokuapp.com/api/create-checkout-session",
        {
          amount: payData.promotionalPrice * 100,
          packName: payData.name,
          bookingData: bookingData,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      setClientSecret(response.data.clientSecret);
      setBookingId(response.data.bookingID)
    } catch (error) {
      console.error('Error fetching client secret:', error);
    }
  }, [formData, payData]);

  useEffect(() => {
    fetchClientSecret();
  }, [fetchClientSecret]);

  const handlePaymentSuccess = async (clientSecret: string) => {
    try {
        console.log("CLIENT SECRERT")
        console.log(clientSecret)

      const response = await axios.post(`https://success-ds-geneva-66508d24e048.herokuapp.com/api/verify-payment?session_id=${clientSecret}`);
      if (response.data.message === 'Booking saved successfully') {
        setPaymentSuccessful(true);
        toast.success('Payment successful! Booking has been made.');
        setDialogOpen(true); // Open dialog on successful payment

      } else {
        toast.error('Failed to verify payment');
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
      toast.error('Failed to verify payment');
    }
  };


  return (
    <>
    <div id="checkout">
      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripePromise}     options={{
            clientSecret,
            onComplete: () => {
              if (bookingId) {
                handlePaymentSuccess(bookingId);
              }
            }
          }}
        >
            <EmbeddedCheckout />
           
        </EmbeddedCheckoutProvider>
      )}
    </div>

   </>
  );
};

export default PaymentForm;
