// styles.ts (or you can place this in your component file directly)

import { makeStyles } from '@material-ui/core/styles';

export const useStyless = makeStyles((theme) => ({
    boldText: {
        fontWeight: 'bold',
    },
    centeredcard: {
        maxWidth: '100%',
        width: '100%',
        padding: theme.spacing(2),
        boxShadow: theme.shadows[5],
        margin: theme.spacing(2, 0),
    },
    centeredButtonContainer: {
        marginTop: theme.spacing(2),
        justifyContent: 'center',
    },
    recapContainer: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        borderRadius: theme.shape.borderRadius,
    },
    recapItem: {
        marginBottom: theme.spacing(1),
    },
    card: {
        cursor: 'pointer',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
            transform: 'scale(1.05)',
        },
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        border: '1px solid #e0e0e0',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: theme.spacing(3),
    },
    containerSession: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
    },
    imageContainer: {
        position: 'relative',
        textAlign: 'left',
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    centeredContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(2),
    },
    image: {
        width: '20%',
        height: '20%',
        marginLeft: '15%',
    },
    title: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        transform: 'translate(-50%, -50%)',

        backgroundColor: 'white',
        padding: '10px',
    },
    infoContainer: {
        marginTop: theme.spacing(2),
        width: '95%',
        marginBottom: theme.spacing(5),
    },
    stepperContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
    },
    stepLabel: {
        fontSize: '20px',
    },
    stepLabelCustom: {
        fontSize: '18px',
    },
    formContainer: {
        width: '95%',
        marginLeft: '2%',
        marginBottom: theme.spacing(5),
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
    },
    buttonColor: {
        backgroundColor: 'rgb(184, 39, 206)',
        color: 'white', // Text color set to white
        '&:hover': {
            backgroundColor: 'rgb(129, 0, 146)', // Darker background color on hover
        },
    },
    buttonColor1: {
        backgroundColor: '#DCDCDC',
        marginRight: '10%',
        color: 'white', // Text color set to white

    },

    cardContent: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const useStyles = makeStyles((theme) => ({
    boldText: {
        fontWeight: 'bold',
    },
    recapContainer: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        borderRadius: theme.shape.borderRadius,
    },
    recapItem: {
        marginBottom: theme.spacing(1),
    },
    container: {
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
        flexDirection: 'column',
        marginTop: theme.spacing(3),
    },
    imageContainer: {
        position: 'relative',
        textAlign: 'left',
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    image: {
        width: '20%',
        height: '20%',
        marginLeft: '15%',
    },
    title: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        transform: 'translate(-50%, -50%)',
        color: 'black',
        fontWeight: 'bold',
        fontSize: '2rem',
        backgroundColor: 'white',
        padding: '10px',
        fontFamily: 'Montserrat, sans-serif',
    },
    infoContainer: {
        marginTop: theme.spacing(2),
        width: '95%',
        marginBottom: theme.spacing(5),
    },
    stepperContainer: {
        marginTop: theme.spacing(2),
    },
    stepLabel: {
        fontSize: '20px',
    },
    stepLabelCustom: {
        fontSize: '18px',
    },
    formContainer: {
        width: '95%',
        marginLeft: '2%',
        marginBottom: theme.spacing(5),
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
    },
    buttonColor: {
        backgroundColor: '', // Default background color
        color: 'white',
        '&:hover': {
            backgroundColor: 'rgb(129, 0, 146)', // Darker background color on hover
        },
    },
    buttonColor1: {
        backgroundColor: '#DCDCDC',
        marginRight: '10%',
        color: 'white',
    },
    card: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    centeredContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(2),
    },
    centeredcard: {
        maxWidth: '100%',
        width: '100%',
        padding: theme.spacing(2),
        boxShadow: theme.shadows[5],
        margin: theme.spacing(2, 0),
    },
    centeredButtonContainer: {
        marginTop: theme.spacing(2),
        justifyContent: 'center',
    },
}));

export default useStyles;
