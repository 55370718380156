import React from 'react';
import {
    TextField,
    FormControlLabel,
    Checkbox,
    FormHelperText
} from '@material-ui/core';
import permis from './images/permis.jpeg'

const UserInfoForm = ({ formData, handleInputChange, handleCheckboxChange, formErrors, lessonDetails, t }) => (
    <div>
        <TextField
            label={t('labels.nom')}
            type="text"
            fullWidth
            required
            value={formData.nom}
            onChange={handleInputChange('nom')}
            margin="normal"
            error={!!formErrors.nom}
            helperText={formErrors.nom && t(formErrors.nom)}
        />
        <TextField
            label={t('labels.prenom')}
            type="text"
            fullWidth
            required
            value={formData.prenom}
            onChange={handleInputChange('prenom')}
            margin="normal"
            error={!!formErrors.prenom}
            helperText={formErrors.prenom && t(formErrors.prenom)}
        />
        <TextField
            label={t('labels.dateNaissance')}
            type="date"
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
            required
            value={formData.dateNaissance}
            onChange={handleInputChange('dateNaissance')}
            margin="normal"
            error={!!formErrors.dateNaissance}
            helperText={formErrors.dateNaissance && t(formErrors.dateNaissance)}
        />
        <TextField
            label={t('labels.email')}
            type="email"
            fullWidth
            required
            value={formData.email}
            onChange={handleInputChange('email')}
            margin="normal"
            error={!!formErrors.email}
            helperText={formErrors.email && t(formErrors.email)}
        />
        <TextField
            label={t('labels.telephone')}
            type="tel"
            fullWidth
            required
            value={formData.telephone}
            onChange={handleInputChange('telephone')}
            margin="normal"
            error={!!formErrors.telephone}
            helperText={formErrors.telephone && t(formErrors.telephone)}
        />
        {lessonDetails && (lessonDetails.name.includes('SENSIBILISATION') || lessonDetails.type.includes('Leçons motos')) &&
            <>
                <TextField
                    label={t('labels.numeroregister')}
                    type="text"
                    fullWidth
                    required
                    value={formData.numeroregister}
                    onChange={handleInputChange('numeroregister')}
                    margin="normal"
                    error={!!formErrors.numeroregister}
                    helperText={formErrors.numeroregister && t(formErrors.numeroregister)}
                />
                <img src={permis} alt="Permis" />
                <br />
            </>
        }
        <FormControlLabel
            control={
                <div>
                    <Checkbox
                        checked={formData.conditionsAcceptees}
                        onChange={handleCheckboxChange}
                        name="conditionsAcceptees"
                    />
                    <FormHelperText>{formErrors.conditionsAcceptees && t(formErrors.conditionsAcceptees)}</FormHelperText>
                </div>
            }
            label={t('labels.conditionsAcceptees')}
        />
    </div>
);

export default UserInfoForm;
