// Step2Recap.tsx

import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './styles'; // Adjust the import path as necessary

interface Step2RecapProps {
    formData: {
        date: string;
        nom: string;
        prenom: string;
        dateNaissance: string;
        email: string;
        telephone: string;
        conditionsAcceptees: boolean;
    };
    t: (key: string) => string;

}


const RecapStep: React.FC<Step2RecapProps> =  ({ formData, t }) => {
    const classes = useStyles();

    return (
        <div className={classes.recapContainer}>
        <Typography variant="h6" className={classes.recapItem}>{t('recap.title')}</Typography>
        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>Date:</span>  {formData.date}
        </Typography>

        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>{t('recap.nom')}:</span> {formData.nom}
        </Typography>
        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>{t('recap.prenom')}:</span> {formData.prenom}
        </Typography>
        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>{t('recap.dateNaissance')}:</span> {formData.dateNaissance}
        </Typography>
        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>{t('recap.email')}:</span> {formData.email}
        </Typography>
        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>{t('recap.telephone')}:</span> {formData.telephone}
        </Typography>
     {/*   <Typography className={classes.recapItem}>
            <span className={classes.boldText}>{t('recap.adresse')}:</span> {formData.adresse}
        </Typography>
        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>{t('recap.numeroregister')}:</span> {formData.numeroregister}
</Typography>*/}
        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>{t('recap.conditionsAcceptees')}:</span> {formData.conditionsAcceptees ? t('common.yes') : t('common.no')}
        </Typography>
    </div>
    );
};

export default RecapStep;
