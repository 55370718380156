import React from 'react';
import { TextField, FormControl, FormHelperText, Button } from '@material-ui/core';
import useStyles from './styles'; // Import styles
import { FormData, Item } from './types'; // Import the types

interface DateStepProps {
    formData: FormData;
    formErrors: any;
    handleInputChange: (prop: keyof FormData) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}



const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};
const DateStep: React.FC<DateStepProps> = ({ formData, formErrors, handleInputChange }) => {
    const classes = useStyles(); // Use styles

    return (
        <div>
                        <TextField
                            label="Date"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            required
                            inputProps={{
                                min: getTodayDate(),
                            }}
                            value={formData.date}
                            onChange={handleInputChange('date')}
                            margin="normal"
                            error={!!formErrors.date}
                            helperText={formErrors.date}
                        />
                    </div>
    );
};

export default DateStep;
