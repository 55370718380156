import { Typography } from '@material-ui/core';
import React from 'react';

const SessionDisplay = ({ formattedSessions }) => {
    return (
        <div>
            {formattedSessions.map((session, index) => (
                <div key={index} style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '4px' }}>
                    <Typography variant="h6" component="pre">{session.title}</Typography>

                    <p>
                        📆 {session.date.title}: {session.date.value}
                    </p>
                    <p>
                        🕦 {session.time.title}: {session.time.value}
                    </p>
                    <p
                        dangerouslySetInnerHTML={{ __html: `📍 ${session.location.title}: ${session.location.value}` }}
                    />
                </div>
            ))}
        </div>
    );
};

export default SessionDisplay;
