// PriceTableConduite.jsx
import React, { useEffect, useState } from 'react';
import ModalComponent from './ModalComponent';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import translateText from './translate';
import { useParams } from 'react-router-dom';

interface PriceTableItemProps {
  value: string;
  name: string;
  features: string[];

}
const PriceTable = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile
  const { t,i18n } = useTranslation();
  const [translatedLessonSimple, setTranslatedLessonSimple] = useState("LEÇON SIMPLE DE CONDUITE");
  const [translatedLessonDouble, setTranslatedLessonDouble] = useState("LEÇON DOUBLE DE CONDUITE");
  const [translatedExamPresentation, setTranslatedExamPresentation] = useState("PRÉSENTATION À L'EXAMEN");
  const [translatedTitle, setTranslatedTitle] = useState("LEÇON DE CONDUITE");
  const { lng } = useParams<{ lng: string }>();
  useEffect(() => {
    if (lng && ['en', 'fr'].includes(lng)) {
      i18n.changeLanguage(lng);
    } else {
      // Handle unsupported language or redirect
      console.error('Unsupported language:', lng);
    }
  }, [lng, i18n]); 
  useEffect(() => {
    const fetchTranslations = async () => {
      // Fetch translations for each title
      const translations = await Promise.all([
        translateText("LEÇON SIMPLE DE CONDUITE", i18n.language), // Replace "fr" with the appropriate target language code
        translateText("LEÇON DOUBLE DE CONDUITE",i18n.language),
        translateText("PRÉSENTATION À L'EXAMEN",i18n.language),
        translateText("LEÇON DE CONDUITE",i18n.language),

      ]);

      setTranslatedLessonSimple(translations[0]);
      setTranslatedLessonDouble(translations[1]);
      setTranslatedExamPresentation(translations[2]);
      setTranslatedTitle(translations[3])
    };

    fetchTranslations();
  }, [i18n.language]);
  return (
    <div className="price " style={{padding: '0'}}>
      <div className="container">
        <h2 className="title price__title" style={{ textAlign: 'center', color:'#002d72' , fontSize:'28px',marginTop:isMobile ? '20%' :'5%'}}>
          <span className="title__mark">{translatedTitle} </span>
        </h2>
        {/*<p className="desc" style={{textAlign: 'left'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>*/}
        
        <div className="price-table">
          <div className="price-table__list " >
          <PriceTableItem value={translatedLessonSimple} name="45 minutes" features={['75 CHF']} />
            <PriceTableItem1 value={translatedLessonDouble} name="90 minutes" features={['160 CHF']} />
            <PriceTableItem2 value={translatedExamPresentation} name="120 minutes" features={['260 CHF']} />
           </div>
        </div>
      </div>
    </div>
  );
};

const PriceTableItem = ({ value, name, features }: PriceTableItemProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t,i18n } = useTranslation();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = () => {
    handleToggle();
    // Autres actions que vous souhaitez effectuer lors du clic sur le bouton "Inscription"
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile
   
  return (
    <div className="col-md-4" >
      <div className="price-table__item"   style={{backgroundColor: "rgba(0,19,37,.9)" /*, transform: "skewY(-1deg)"*/}}>
        <div className="price-table__value1" style={{color: "#FFF",  font: "inherit", fontWeight:'500'}}>{value}</div> 
        <h3 className="price-table__name">{name}</h3>
        <ul className="price-table__feature-list" style={{paddingLeft: "0"}}>
          {features.map((feature, index) => (
            <li key={index}  style={{color: "#FFF", fontWeight:'500'}}>{feature}</li>
          ))}
        </ul>
        <button className="btn price-table__btn popup-bestsellers-btn" onClick={handleClick}>
          <span className="btn__text">{t('timeline5.book')}</span>
        </button>

      </div>
      <ModalComponent isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />

    </div>
  );
};
const PriceTableItem1 = ({ value, name, features }: PriceTableItemProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t,i18n } = useTranslation();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = () => {
    handleToggle();
    // Autres actions que vous souhaitez effectuer lors du clic sur le bouton "Inscription"
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile
   
  return (
      <div className="col-md-4" >
        <div className="price-table__item"   style={{backgroundColor: "rgba(0,19,37,.9)" /*, transform: "skewY(-1deg)"*/}}>
          <div className="price-table__value1" style={{color: "#FFF",  font: "inherit", fontWeight:'500'}}>{value}</div>
          <h3 className="price-table__name">{name}</h3>
          <ul className="price-table__feature-list" style={{paddingLeft: "0"}} >
            {features.map((feature, index) => (
              <li key={index} style={{color: "#FFF", fontWeight:'500'}}>{feature}</li>
            ))}
          </ul>
          <button className="btn price-table__btn popup-bestsellers-btn" onClick={handleClick}>
            <span className="btn__text">{t('timeline5.book')}</span>
          </button>
          <ModalComponent isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />

        </div>
      </div>
    );
  };


  const PriceTableItem2 = ({ value, name, features}: PriceTableItemProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { t,i18n } = useTranslation();

    const handleToggle = () => {
      setIsOpen(!isOpen);
    };
  
    const handleClick = () => {
      handleToggle();
      // Autres actions que vous souhaitez effectuer lors du clic sur le bouton "Inscription"
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile
     
    return (
    <div className="col-md-4" >
      <div className="price-table__item"   style={{backgroundColor: "rgba(0,19,37,.9)" /*, transform: "skewY(-1deg)"*/}}>
        <div className="price-table__value1" style={{color: "#FFF", font: "inherit", fontWeight:'500'}}>{value} {/*<span className="price-table__discount">{discount}</span>cercle remise*/ }</div>
        <h3 className="price-table__name">{name}</h3>
        <ul className="price-table__feature-list" style={{paddingLeft: "0"}}>
          {features.map((feature, index) => (
            <li key={index} style={{color: "#FFF", fontWeight:'500'}}>{feature}</li>
          ))}
        </ul>
        <button className="btn" disabled  >
          <span className="btn__text">{t('unvaiblable')}</span>
        </button>
        <ModalComponent isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />

      </div>
    </div>
  );
};



export default PriceTable;
