import React, { useEffect } from 'react';
import TiserSlider from './TiserSlider'; // Assurez-vous d'ajuster le chemin en fonction de votre structure
import PriceTableItem from './PriceTableItem';
import BestFeatures from './BestFeatures';
import AboutComponent1 from './AboutComponent1';
import CourseList from './CourseList';
import Etapes from './Etapes';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Timeline from './Etapes_mobile';
import { Layout } from '@douyinfe/semi-ui';
import ToolbarComponent from './ToolbarComponent';
import CustomTimeline from './CustomTimeline';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const {Content } = Layout;

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:1100px)'); // Check if screen size is mobile
  const { i18n } = useTranslation();
  const { lng } = useParams<{ lng: string }>();
  useEffect(() => {
    if (lng && ['en', 'fr'].includes(lng)) {
      i18n.changeLanguage(lng);
    } else {
      // Handle unsupported language or redirect
      console.error('Unsupported language:', lng);
    }
  }, [lng, i18n]);
    return (
      <div>
          
          <TiserSlider />
          <BestFeatures/>
          <AboutComponent1/>
          {isMobile ? <Timeline /> : <CustomTimeline /> } {/* Render EtapesMobile on mobile, otherwise render Etapes */}
          <PriceTableItem/>
          <CourseList/>
        </div>
    );
}

export default Home;
