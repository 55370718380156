import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FaAngleDoubleDown } from 'react-icons/fa';
import ModalComponent from './ModalComponent';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import translateText from './translate';

interface Item {
  id: number;
  type: string;
  name: string;
  price: number;
  promotionalPrice: number;
  features: string[];
}

const PriceTable = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [langue, setLangue] = useState('FRANCAIS');

  const [data, setData] = useState<Item[]>([]);
  const { lng } = useParams<{ lng: string }>();
  useEffect(() => {
    if (lng && ['en', 'fr'].includes(lng)) {
      i18n.changeLanguage(lng);
    } else {
      // Handle unsupported language or redirect
      console.error('Unsupported language:', lng);
    }
  }, [lng, i18n]); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        let lang ="FRANCAIS"
        const response = await axios.get<Item[]>('https://success-ds-geneva-66508d24e048.herokuapp.com/api/lessons');

        if (lng === 'en') {
          setLangue("ENGLISH")
          lang="ENGLISH"
        } else {
          setLangue("FRANCAIS")
          lang="FRANCAIS"

        }

        const filteredData = response.data.filter(item => item.type === 'Pack' && item.name.includes(lang));

       // Get the first 3 items from the filtered data
       const firstThreeItems = filteredData.slice(0, 3);
        
       setData(firstThreeItems);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [i18n.language,lng]);

  const getRibbonClass = (index: number) => {
    const classes = ['ribbon6 orange', 'ribbon6 green', 'ribbon6 yellow', 'ribbon6 green', 'ribbon6 yellow'];
    return classes[index % classes.length];
  };

  const handleIconClick = () => {
    navigate("/courses");
    window.scrollTo(0, 0); // Scroll to the top of the page after navigation
  };
  const splitFeatures = (feature: string) => {
    return feature
      .trim()
      .replace(/^- */, '')
      .split(/ - |\n/)
      .map(f => f.trim());
  };
  return (
    <div className="price" style={{ padding: '0' }}>
      <div className="container">
        <h2 className="title price__title" style={{ textAlign: 'center', color: '#002d72', marginTop: isMobile ? '20%' : '9%' }}>
          <span className="title__mark" style={{ fontSize: '35px' }}>{t('priceTable.title')}</span>
        </h2>
        <div className="price-table">
          <div className="price-table__list">
            {data.map((item, index) => (
              <PriceTableItem
                key={index}
                index={item.id}
                value={item.name}
                features={splitFeatures(item.features[0])}
                dataPriceIn={`${item.promotionalPrice} CHF`}
                dataPrice={`${item.price} CHF`}
                dataName={item.name}
                dataActive="no" // Adjust as per your data structure
                showIcon={index === 2} // Example condition for showing icon
              />
            ))}
            <div style={{ textAlign: 'center', paddingBottom: '50px', marginTop: isMobile ? '14%' : '2%' }}>
              <button onClick={handleIconClick} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#5881a9' }}>
                <i className="material-icons" style={{ fontSize: '40px' }}><FaAngleDoubleDown /></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const generateRandomIndexes = (dataLength: number, count: number): number[] => {
  const indexes: number[] = [];
  while (indexes.length < count) {
    const randomIndex = Math.floor(Math.random() * dataLength);
    if (!indexes.includes(randomIndex)) {
      indexes.push(randomIndex);
    }
  }
  return indexes;
};

export default PriceTable;

interface PriceTableItemProps {
  index: number;
  value: string;
  features: string[];
  dataPriceIn: string;
  dataPrice: string;
  dataName: string;
  dataActive: string; // Adjust type as per your data structure
  showIcon: boolean;
}

const PriceTableItem: React.FC<PriceTableItemProps> = ({ index, value, features, dataPriceIn, dataPrice, dataName, dataActive, showIcon }) => {
  const { t,i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { lng } = useParams<{ lng: string }>();
  useEffect(() => {
    if (lng && ['en', 'fr'].includes(lng)) {
      i18n.changeLanguage(lng);
    } else {
      // Handle unsupported language or redirect
      console.error('Unsupported language:', lng);
    }
  }, [lng, i18n]); 
  const [translatedValue, setTranslatedValue] = useState(value); // State for translated value
  const [translatedFeatures, setTranslatedFeatures] = useState<string[]>(features); // State for translated features

  useEffect(() => {
    const fetchTranslations = async () => {
      const translatedValueText = await translateText(value, i18n.language);
      const translatedFeaturesTexts = await Promise.all(features.map(feature => translateText(feature, i18n.language)));

      setTranslatedValue(translatedValueText);
      setTranslatedFeatures(translatedFeaturesTexts);
    };

    fetchTranslations();
  }, [value, features, i18n.language]);


  const handleClick = () => {
    navigate(`/${i18n.language}/DetailPageMobile/` + index);
    // Additional actions you want to perform on "Inscription" button click
  };

  const handleIconClick = () => {
    navigate("/courses");
    window.scrollTo(0, 0); // Scroll to the top of the page after navigation
  };
  // Function to split value into two lines based on parentheses
  const renderValue = (value: string) => {
    const indexParentheses = value.indexOf('(');
    if (indexParentheses !== -1) {
      return (
        <>
          {value.substring(0, indexParentheses).trim()} <br /> {/* First line */}
          {value.substring(indexParentheses).trim()} {/* Second line */}
        </>
      );
    } else {
      return value; // If no parentheses found, return original value
    }
  };

  return (
    <div className="col-md-4">
      <div className="price-table__item" style={{ height: '365px' }}>
        <div className="wrap">
          <span className={getRibbonClass(index)}><span>{dataPriceIn}</span></span>
        </div>
        <div className="price-table__value" style={{ color: '#002d72' }}>{renderValue(translatedValue)}</div>
        <h3 className="price-table__name" style={{ color: '#000' }}> <del>{dataPrice}</del> </h3>
        <ul className="price-table__feature-list">
          {translatedFeatures.map((feature, featureIndex) => (
            <li key={featureIndex} className="price-table__feature-item">{feature}</li>
          ))}
        </ul>
        <button className="btn price-table__btn popup-bestsellers-btn" onClick={handleClick}>
          <span className="btn__text">{t('priceTable1.inscription')}</span>
          <div className="data-form" data-price={dataPrice} data-name={dataName} data-active={dataActive}></div>
        </button>
      </div>

    </div>
  );
};

const getRibbonClass = (index: number) => {
  const classes = ['ribbon6 orange', 'ribbon6 green', 'ribbon6 yellow', 'ribbon6 green', 'ribbon6 yellow'];
  return classes[index % classes.length];
};



